<div class="lifecycle_modalwrap">
    <ng-container *ngIf="inHtmlDispo">
        <div class="dialoghead">
            <h1 class="mat-mdc-dialog-title">Disposition Lifecycle</h1>
            <mat-dialog-content>
                <p *ngFor="let names of lifeCycleData">{{names.name}}<i class="fa fa-question-circle" aria-hidden="true" mat-raised-button
                  matTooltip="Added By: {{names.username ? names.username : 'System Generated'}},  Added On: ({{names.datetime}})"
                  matTooltipPosition="below"></i></p>
            </mat-dialog-content>
            <mat-progress-bar
                *ngIf="loader"
                mode="indeterminate"
              ></mat-progress-bar>
          </div>
          
          <span mat-button mat-dialog-close class="close-btn-modal material-icons" mat-raised-button
          matTooltip="Close"
          matTooltipPosition="below"
            >close</span
          >
    </ng-container>
    
      <ng-container *ngIf="!inHtmlDispo">
        <div class="dialoghead">
            <h1 class="mat-mdc-dialog-title">Owner Lifecycle</h1>
            <mat-dialog-content>
                <p *ngFor="let names of lifeCycleData">{{names.name}}
                  <i class="fa fa-question-circle" aria-hidden="true" mat-raised-button
                  matTooltip="Added By: {{names.username == 'N/A' || names.username == null ? 'System Generated' : names.username }},  Added On: ({{names.datetime}})"
                  matTooltipPosition="below"></i>
                </p>
            </mat-dialog-content>
            <mat-progress-bar
                *ngIf="loader"
                mode="indeterminate"
              ></mat-progress-bar>
          </div>
          
          <span mat-button mat-dialog-close class="close-btn-modal material-icons"  mat-raised-button
          matTooltip="Close"
          matTooltipPosition="below"
            >close</span
          >
      </ng-container>
</div>