<div class="preview_modal">
  <h1 class="preview_header">Preview : {{ data.value.name }}</h1>
  <mat-dialog-content>
    <div>
      <p *ngIf="data.value.name" class="preview_data">
        <span><strong>Name:</strong></span>
        <span class="preview_value">{{ data.value.name }}</span>
      </p>
      <p *ngIf="data.value.email" class="preview_data">
        <span><strong>Email:</strong></span>
        <span class="preview_value">{{ data.value.email }}</span>
      </p>
      <p *ngIf="data.value.phone_number" class="preview_data">
        <span><strong>Phone Number:</strong></span>
        <span class="preview_value">{{ data.value.phone_number }}</span>
      </p>
      <!-- <p *ngIf="data.value.rep_id" class="preview_data">
        <span><strong>Rep ID:</strong></span>
        <span class="preview_value">{{ data.value.rep_id }}</span>
      </p>
      <p *ngIf="data.value.source && data.value.source.length > 0" class="preview_data">
        <span><strong>Source:</strong></span>
        <span class="preview_value">{{ data.value.source.join(', ') }}</span>
      </p> -->
      <p *ngIf="data.value.status" class="preview_data">
        <span><strong>Status:</strong></span>
        <span class="preview_value">{{ data.value.status }}</span>
      </p>
      <p *ngIf="data.value.priority" class="preview_data">
        <span><strong>Priority:</strong></span>
        <span class="preview_value">{{ data.value.priority }}</span>
      </p>
      <p *ngIf="data.value.timezone" class="preview_data">
        <span><strong>Timezone:</strong></span>
        <span class="preview_value">{{ data.value.timezone }}</span>
      </p>
      <p *ngIf="data.value.company" class="preview_data">
        <span><strong>Company:</strong></span>
        <span class="preview_value">{{ data.value.company }}</span>
      </p>
      <p *ngIf="data.value.other_info" class="preview_data">
        <span><strong>Other Info:</strong></span>
        <span class="preview_value">{{ data.value.other_info }}</span>
      </p>
      <p *ngIf="data.value.title" class="preview_data">
        <span><strong>Title:</strong></span>
        <span class="preview_value">{{ data.value.title }}</span>
      </p>
      <p *ngIf="data.value.faculty_name" class="preview_data">
        <span><strong>Faculty Name:</strong></span>
        <span class="preview_value">{{ data.value.faculty_name }}</span>
      </p>
      <p *ngIf="data.value.gender" class="preview_data">
        <span><strong>Gender:</strong></span>
        <span class="preview_value">{{ data.value.gender }}</span>
      </p>
      <p *ngIf="data.value.secondary_email" class="preview_data">
        <span><strong>Secondary Email:</strong></span>
        <span class="preview_value">{{ data.value.secondary_email }}</span>
      </p>
      <p *ngIf="data.value.secondary_phone" class="preview_data">
        <span><strong>Secondary Phone:</strong></span>
        <span class="preview_value">{{ data.value.secondary_phone }}</span>
      </p>
      <p *ngIf="data.value.provider_number" class="preview_data">
        <span><strong>Provider Number:</strong></span>
        <span class="preview_value">{{ data.value.provider_number }}</span>
      </p>
      <p *ngIf="data.value.lab_volume" class="preview_data">
        <span><strong>Lab Volume:</strong></span>
        <span class="preview_value">{{ data.value.lab_volume }}</span>
      </p>
      <p *ngIf="data.value.city" class="preview_data">
        <span><strong>City:</strong></span>
        <span class="preview_value">{{ data.value.city }}</span>
      </p>
      <p *ngIf="data.value.state" class="preview_data">
        <span><strong>State:</strong></span>
        <span class="preview_value">{{ data.value.state }}</span>
      </p>
      <p *ngIf="data.value.camp_unique_id" class="preview_data">
        <span><strong>Camp Unique ID:</strong></span>
        <span class="preview_value">{{ data.value.camp_unique_id }}</span>
      </p>
      <!-- <p *ngIf="data.value.category && data.value.category.length > 0" class="preview_data">
        <span><strong>Category:</strong></span>
        <span class="preview_value">{{ data.value.category.join(', ') }}</span>
      </p> -->
      <!-- <p *ngIf="data.value.tag && data.value.tag.length > 0" class="preview_data">
        <span><strong>Tag:</strong></span>
        <span class="preview_value">{{ data.value.tag.join(', ') }}</span>
      </p> -->
      <p *ngIf="data.value.years_in_business" class="preview_data">
        <span><strong>Years in Business:</strong></span>
        <span class="preview_value">{{ data.value.years_in_business }}</span>
      </p>
      <p *ngIf="data.value.employee_size" class="preview_data">
        <span><strong>Employee Size:</strong></span>
        <span class="preview_value">{{ data.value.employee_size }}</span>
      </p>
      <p *ngIf="data.value.industry" class="preview_data">
        <span><strong>Industry:</strong></span>
        <span class="preview_value">{{ data.value.industry }}</span>
      </p>
      <p *ngIf="data.value.total_expenses" class="preview_data">
        <span><strong>Total Expenses:</strong></span>
        <span class="preview_value">{{ data.value.total_expenses }}</span>
      </p>
      <p *ngIf="data.value.linkedin" class="preview_data">
        <span><strong>Linked In Url:</strong></span>
        <span class="preview_value">{{ data.value.linkedin }}</span>
      </p>
      <p *ngIf="data.value.website" class="preview_data">
        <span><strong>Website:</strong></span>
        <span class="preview_value">{{ data.value.website }}</span>
      </p>
      <p *ngIf="data.value.company_linkedin_url" class="preview_data">
        <span><strong>Company Linked In Url:</strong></span>
        <span class="preview_value">{{ data.value.company_linkedin_url }}</span>
      </p>
      <p *ngIf="data.value.url.length > 0" class="preview_data">
        <span><strong>URL:</strong></span>
        <span class="preview_value"><span *ngFor="let url of data.value.url">{{url.url}}, </span></span>
      </p>
      <p *ngIf="data.value.disposition" class="preview_data">
        <span><strong>Disposition:</strong></span>
        <span class="preview_value">{{ data.value.disposition }}</span>
      </p>
      <p *ngIf="data.value.payermix" class="preview_data">
        <span><strong>Payermix:</strong></span>
        <span class="preview_value">{{ data.value.payermix }}</span>
      </p>
      <p *ngIf="data.value.allowed_private_margin" class="preview_data">
        <span><strong>Allowed Private Margin:</strong></span>
        <span class="preview_value">{{ data.value.allowed_private_margin }}</span>
      </p>
      <p *ngIf="data.value.bad_debt" class="preview_data">
        <span><strong>Bad debt:</strong></span>
        <span class="preview_value">{{ data.value.bad_debt }}</span>
      </p>
      <p *ngIf="data.value.annual_revenue" class="preview_data">
        <span><strong>Annual Revenue:</strong></span>
        <span class="preview_value">{{ data.value.annual_revenue }}</span>
      </p>
      <p *ngIf="data.value.source_type" class="preview_data">
        <span><strong>Source Type:</strong></span>
        <span class="preview_value">{{ data.value.source_type }}</span>
      </p>
      <!-- <p *ngIf="data.value.owner" class="preview_data">
        <span><strong>Owner:</strong></span>
        <span class="preview_value">{{ data.value.owner }}</span>
      </p> -->
      <p *ngIf="data.value.practice_name" class="preview_data">
        <span><strong>Practice Name:</strong></span>
        <span class="preview_value">{{ data.value.practice_name }}</span>
      </p>
      <p *ngIf="data.value.contact_person_email" class="preview_data">
        <span><strong>Contact Person Email:</strong></span>
        <span class="preview_value">{{ data.value.contact_person_email }}</span>
      </p>
      <p *ngIf="data.value.taxo_list && data.value.taxo_list.length > 0" class="preview_data">
        <span><strong>Taxo List:</strong></span>
        <span class="preview_value">{{ data.value.taxo_list.join(', ') }}</span>
      </p>
      <p *ngIf="data.value.npi_no" class="preview_data">
        <span><strong>NPI No:</strong></span>
        <span class="preview_value">{{ data.value.npi_no }}</span>
      </p>
      <p *ngIf="data.value.address" class="preview_data">
        <span><strong>Address:</strong></span>
        <span class="preview_value">{{ data.value.address }}</span>
      </p>
      <p *ngIf="data.value.city" class="preview_data">
        <span><strong>City:</strong></span>
        <span class="preview_value">{{ data.value.city }}</span>
      </p>
      <p *ngIf="data.value.country" class="preview_data">
        <span><strong>Country:</strong></span>
        <span class="preview_value">{{ data.value.country }}</span>
      </p>
      <p *ngIf="data.value.state" class="preview_data">
        <span><strong>State:</strong></span>
        <span class="preview_value">{{ data.value.state }}</span>
      </p>
      <p *ngIf="data.value.zip" class="preview_data">
        <span><strong>Zip:</strong></span>
        <span class="preview_value">{{ data.value.zip }}</span>
      </p>
      <p *ngIf="data.value.other_information" class="preview_data">
        <span><strong>Other Information:</strong></span>
        <span class="preview_value"><span *ngFor="let key of getKeys(otherInformation)"
            class="other_information_inprev">
            <strong>{{key.split("_").join(" ")}}: </strong>{{otherInformation[key] == "null" ? "NA" :
            otherInformation[key]}}
          </span></span>
      </p>
      <p *ngIf="data.value.created_on" class="preview_data">
        <span><strong>Created On:</strong></span>
        <span class="preview_value">{{created_on}}</span>
      </p>
    </div>
  </mat-dialog-content>
  <span mat-button mat-dialog-close class="close-btn-modal material-icons lead_preview_close" mat-raised-button
    matTooltip="Close" matTooltipPosition="below" (click)="closePreview()">close</span>
</div>




<!-- <div>
    <p *ngIf="data.value.name" class="preview_data">
      <span><strong>Name:</strong></span>
      <span class="preview_value">{{ data.value.name }}</span>
    </p>
    <p *ngIf="data.value.email" class="preview_data">
      <span><strong>Email:</strong></span>
      <span class="preview_value">{{ data.value.email }}</span>
    </p>
    <p *ngIf="data.value.title" class="preview_data">
      <span><strong>Title:</strong></span>
      <span class="preview_value">{{ data.value.title }}</span>
    </p>
    <p *ngIf="data.value.phone_number" class="preview_data">
      <span><strong>Phone Number:</strong></span>
      <span class="preview_value">{{ data.value.phone_number }}</span>
    </p>
    <p *ngIf="data.value.organisation_name" class="preview_data">
      <span><strong>Organisation Name:</strong></span>
      <span class="preview_value">{{ data.value.organisation_name }}</span>
    </p>
    <p *ngIf="data.value.employee_size" class="preview_data">
      <span><strong>Number of Employees:</strong></span>
      <span class="preview_value">{{ data.value.employee_size }}</span>
    </p>
    <p *ngIf="data.value.annual_revenue" class="preview_data">
      <span><strong>Annual Revenue:</strong></span>
      <span class="preview_value">{{ data.value.annual_revenue }}</span>
    </p>
    <p *ngIf="data.value.location_name" class="preview_data">
      <span><strong>Location Name:</strong></span>
      <span class="preview_value">{{ data.value.location_name }}</span>
    </p>
    <p *ngIf="data.value.address" class="preview_data">
      <span><strong>Address:</strong></span>
      <span class="preview_value">{{ data.value.address }}</span>
    </p>
    <p *ngIf="data.value.secondary_email" class="preview_data">
      <span><strong>Secondary Email:</strong></span>
      <span class="preview_value">{{ data.value.secondary_email }}</span>
    </p>
    <p *ngIf="data.value.secondary_phone" class="preview_data">
      <span><strong>Secondary Phone:</strong></span>
      <span class="preview_value">{{ data.value.secondary_phone }}</span>
    </p>
    <p *ngIf="data.value.url.length > 0" class="preview_data">
      <span><strong>URL:</strong></span>
      <span class="preview_value"><span *ngFor="let url of data.value.url">{{url.url}}, </span></span>
    </p>
    <p *ngIf="data.value.city" class="preview_data">
      <span><strong>City:</strong></span>
      <span class="preview_value">{{ data.value.city }}</span>
    </p>
    <p *ngIf="data.value.state" class="preview_data">
      <span><strong>State:</strong></span>
      <span class="preview_value">{{ data.value.state }}</span>
    </p>
    <p *ngIf="data.value.zip" class="preview_data">
      <span><strong>Zip:</strong></span>
      <span class="preview_value">{{ data.value.zip }}</span>
    </p>
    <p *ngIf="data.value.other_information" class="preview_data">
      <span><strong>Other Information:</strong></span>
      <span class="preview_value"><span *ngFor="let key of getKeys(otherInformation)" class="other_information_inprev">
        <strong>{{key.split("_").join(" ")}}: </strong>{{otherInformation[key] == "null" ? "NA" : otherInformation[key]}}
      </span></span>
    </p>

   </div> -->