<div class="preview_modal lead_activity_list">
  <h1 class="preview_header">Lead's Activity List</h1>
  <mat-dialog-content>
    <div *ngIf="tabledatatalist && tabledatatalist.length > 0; else noData">
      <div *ngFor="let dataItem of tabledatatalist; let i = index">
        <div *ngIf="dataItem && dataItem.lead_name" class="preview_data">
          <div class="preview_value lead-activity">
            <p style="margin-bottom: 0;">{{i + 1}}. {{dataItem.user_name}} {{dataItem.event}}
              {{dataItem.lead_name}} ({{dataItem.lead_email}})</p>

            <ng-container
              *ngIf="dataItem.date || dataItem.note || dataItem.followup_date || dataItem.start_time || (dataItem.files && dataItem.files.length > 0)">
              <span *ngIf="dataItem.date"><b>Date:</b> {{dataItem.date}}</span>
              <span *ngIf="dataItem.note"><b>Notes:</b> {{dataItem.note}}</span>
              <span *ngIf="dataItem.followup_date"><b>Follow Up Date:</b> {{dataItem.followup_date}}</span>
              <span *ngIf="dataItem.start_time"><b>Follow Up Time:</b> {{dataItem.start_time}}</span>
                <span *ngIf="dataItem.title" class="ickon_preview"><b>File Name:</b> {{dataItem.title}} <ng-container *ngIf="dataItem.files"> <i class="fa fa-eye file-preview-class"  matTooltip="Preview File" matTooltipPosition="below"aria-hidden="true"(click)="previewFile(dataItem)"></i>
                  
                </ng-container></span>
            </ng-container>
          </div>
        </div>

        <p *ngIf="!dataItem || !dataItem.lead_name" class="preview_data">
          {{i + 1}}. No activity available.
        </p>
      </div>
    </div>

    <ng-template #noData>
      <div class="no-data-container">
        <p class="no-data-message">Oops!<br>NO Record Found</p>
      </div>
    </ng-template>
  </mat-dialog-content>

  <span mat-button mat-dialog-close class="close-btn-modal material-icons lead_preview_close" mat-raised-button
    matTooltip="Close" matTooltipPosition="below" (click)="closePreview()">
    close
  </span>
</div>