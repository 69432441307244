<div class="preview_modal">
    <h1 class="preview_header">Preview The Details Below:
    </h1>
    <mat-dialog-content>
        <ng-container *ngFor="let field of fields; let i = index">
            <mat-expansion-panel class="preview_panel" id="leadviewView" [expanded]="i === 0">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <!-- Lead Info Preview {{ i + 1 }} -->
                        Lead Preview for {{field.name}} add on {{converTimeReadAbleFormat(field.created_on)}} by Rep
                        name
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-dialog-content>
                    <div style="background-color: #ddd8d8;">
                        <p *ngIf="field.name" class="preview_data">
                            <span><strong>Name:</strong></span>
                            <span class="preview_value">{{ field.name }}</span>
                        </p>
                        <p *ngIf="field.email" class="preview_data">
                            <span><strong>Email:</strong></span>
                            <span class="preview_value">{{ field.email }}</span>
                        </p>
                        <p *ngIf="field.phone_number" class="preview_data">
                            <span><strong>Phone Number:</strong></span>
                            <span class="preview_value">{{ field.phone_number }}</span>
                        </p>
                        <p *ngIf="field.status" class="preview_data">
                            <span><strong>Status:</strong></span>
                            <span class="preview_value">{{ field.status }}</span>
                        </p>
                        <p *ngIf="field.priority" class="preview_data">
                            <span><strong>Priority:</strong></span>
                            <span class="preview_value">{{ field.priority }}</span>
                        </p>
                        <p *ngIf="field.timezone" class="preview_data">
                            <span><strong>Timezone:</strong></span>
                            <span class="preview_value">{{ field.timezone }}</span>
                        </p>
                        <p *ngIf="field.company" class="preview_data">
                            <span><strong>Company:</strong></span>
                            <span class="preview_value">{{ field.company }}</span>
                        </p>
                        <p *ngIf="field.other_info" class="preview_data">
                            <span><strong>Other Info:</strong></span>
                            <span class="preview_value">{{ field.other_info }}</span>
                        </p>
                        <p *ngIf="field.title" class="preview_data">
                            <span><strong>Title:</strong></span>
                            <span class="preview_value">{{ field.title }}</span>
                        </p>
                        <p *ngIf="field.faculty_name" class="preview_data">
                            <span><strong>Faculty Name:</strong></span>
                            <span class="preview_value">{{ field.faculty_name }}</span>
                        </p>
                        <p *ngIf="field.gender" class="preview_data">
                            <span><strong>Gender:</strong></span>
                            <span class="preview_value">{{ field.gender }}</span>
                        </p>
                        <p *ngIf="field.secondary_email" class="preview_data">
                            <span><strong>Secondary Email:</strong></span>
                            <span class="preview_value">{{ field.secondary_email }}</span>
                        </p>
                        <p *ngIf="field.secondary_phone" class="preview_data">
                            <span><strong>Secondary Phone:</strong></span>
                            <span class="preview_value">{{ field.secondary_phone }}</span>
                        </p>
                        <p *ngIf="field.provider_number" class="preview_data">
                            <span><strong>Provider Number:</strong></span>
                            <span class="preview_value">{{ field.provider_number }}</span>
                        </p>
                        <p *ngIf="field.lab_volume" class="preview_data">
                            <span><strong>Lab Volume:</strong></span>
                            <span class="preview_value">{{ field.lab_volume }}</span>
                        </p>
                        <p *ngIf="field.city" class="preview_data">
                            <span><strong>City:</strong></span>
                            <span class="preview_value">{{ field.city }}</span>
                        </p>
                        <p *ngIf="field.state" class="preview_data">
                            <span><strong>State:</strong></span>
                            <span class="preview_value">{{ field.state }}</span>
                        </p>
                        <p *ngIf="field.camp_unique_id" class="preview_data">
                            <span><strong>Camp Unique ID:</strong></span>
                            <span class="preview_value">{{ field.camp_unique_id }}</span>
                        </p>
                        <p *ngIf="field.years_in_business" class="preview_data">
                            <span><strong>Years in Business:</strong></span>
                            <span class="preview_value">{{ field.years_in_business }}</span>
                        </p>
                        <p *ngIf="field.employee_size" class="preview_data">
                            <span><strong>Employee Size:</strong></span>
                            <span class="preview_value">{{ field.employee_size }}</span>
                        </p>
                        <p *ngIf="field.industry" class="preview_data">
                            <span><strong>Industry:</strong></span>
                            <span class="preview_value">{{ field.industry }}</span>
                        </p>
                        <p *ngIf="field.total_expenses" class="preview_data">
                            <span><strong>Total Expenses:</strong></span>
                            <span class="preview_value">{{ field.total_expenses }}</span>
                        </p>
                        <p *ngIf="field.linkedin" class="preview_data">
                            <span><strong>Linked In Url:</strong></span>
                            <span class="preview_value">{{ field.linkedin }}</span>
                        </p>
                        <p *ngIf="field.website" class="preview_data">
                            <span><strong>Website:</strong></span>
                            <span class="preview_value">{{ field.website }}</span>
                        </p>
                        <p *ngIf="field.company_linkedin_url" class="preview_data">
                            <span><strong>Company Linked In Url:</strong></span>
                            <span class="preview_value">{{ field.company_linkedin_url }}</span>
                        </p>
                        <p *ngIf="field.url.length > 0" class="preview_data">
                            <span><strong>URL:</strong></span>
                            <span class="preview_value"><span *ngFor="let url of field.url">{{url.url}}, </span></span>
                        </p>
                        <p *ngIf="field.disposition" class="preview_data">
                            <span><strong>Disposition:</strong></span>
                            <span class="preview_value">{{ field.disposition }}</span>
                        </p>
                        <p *ngIf="field.payermix" class="preview_data">
                            <span><strong>Payermix:</strong></span>
                            <span class="preview_value">{{ field.payermix }}</span>
                        </p>
                        <p *ngIf="field.allowed_private_margin" class="preview_data">
                            <span><strong>Allowed Private Margin:</strong></span>
                            <span class="preview_value">{{ field.allowed_private_margin }}</span>
                        </p>
                        <p *ngIf="field.bad_debt" class="preview_data">
                            <span><strong>Bad debt:</strong></span>
                            <span class="preview_value">{{ field.bad_debt }}</span>
                        </p>
                        <p *ngIf="field.annual_revenue" class="preview_data">
                            <span><strong>Annual Revenue:</strong></span>
                            <span class="preview_value">{{ field.annual_revenue }}</span>
                        </p>
                        <p *ngIf="field.source_type" class="preview_data">
                            <span><strong>Source Type:</strong></span>
                            <span class="preview_value">{{ field.source_type }}</span>
                        </p>
                        <p *ngIf="field.practice_name" class="preview_data">
                            <span><strong>Practice Name:</strong></span>
                            <span class="preview_value">{{ field.practice_name }}</span>
                        </p>
                        <p *ngIf="field.contact_person_email" class="preview_data">
                            <span><strong>Contact Person Email:</strong></span>
                            <span class="preview_value">{{ field.contact_person_email }}</span>
                        </p>
                        <p *ngIf="field.taxo_list && field.taxo_list.length > 0" class="preview_data">
                            <span><strong>Taxo List:</strong></span>
                            <span class="preview_value">{{ field.taxo_list.join(', ') }}</span>
                        </p>
                        <p *ngIf="field.npi_no" class="preview_data">
                            <span><strong>NPI No:</strong></span>
                            <span class="preview_value">{{ field.npi_no }}</span>
                        </p>
                        <p *ngIf="field.address" class="preview_data">
                            <span><strong>Address:</strong></span>
                            <span class="preview_value">{{ field.address }}</span>
                        </p>
                        <p *ngIf="field.city" class="preview_data">
                            <span><strong>City:</strong></span>
                            <span class="preview_value">{{ field.city }}</span>
                        </p>
                        <p *ngIf="field.country" class="preview_data">
                            <span><strong>Country:</strong></span>
                            <span class="preview_value">{{ field.country }}</span>
                        </p>
                        <p *ngIf="field.state" class="preview_data">
                            <span><strong>State:</strong></span>
                            <span class="preview_value">{{ field.state }}</span>
                        </p>
                        <p *ngIf="field.zip" class="preview_data">
                            <span><strong>Zip:</strong></span>
                            <span class="preview_value">{{ field.zip }}</span>
                        </p>
                        <p *ngIf="field.other_information" class="preview_data">
                            <span><strong>Other Information:</strong></span>
                            <span class="preview_value"><span *ngFor="let key of getKeys(field.other_information)"
                                    class="other_information_inprev">
                                    <strong>{{key.split("_").join(" ")}}: </strong>{{field.other_information[key] ==
                                    "null" ?
                                    "NA" :
                                    field.other_information[key]}}
                                </span></span>
                        </p>
                        <p *ngIf="field.created_on" class="preview_data">
                            <span><strong>Created On:</strong></span>
                            <span class="preview_value">{{converTimeReadAbleFormat(field.created_on)}}</span>
                        </p>
                    </div>
                </mat-dialog-content>
            </mat-expansion-panel>
        </ng-container>
    </mat-dialog-content>

    <div class="sub-title wrapper lead-submit-div">
        <h2>The Provided Email Already Exist in some of Expired Leads. Do you want to add them again?</h2>
        <div style="display: flex; justify-content: space-between; width: 200px; margin: auto;" class="lead-submit-div-yes-no">
            <button mat-button class="add_btn" (click)="cancelButton()">No</button>
            <button mat-button class="add_btn" (click)="leadSubmit()">Yes</button>
        </div>
    </div>

</div>