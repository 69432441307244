import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogData } from 'listing-angular15';
import { CookieService } from 'ngx-cookie-service';
import { ApiservicesService } from 'src/app/services/apiservices.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { AttendComponent } from '../../attend/attend.component';
import { PreviewComponent } from 'src/app/Common-components/preview/preview.component';
import { RescheduleFollowupComponent } from '../../reschedule-followup/reschedule-followup.component';
import { DispositionModal } from '../../lead-list/lead-list.component';
import { CommonModule } from '@angular/common';
import { FollowupComponent } from '../../followup/followup.component';
import { LeadPreviewComponent } from 'src/app/Common-components/lead-preview/lead-preview.component';
import { LeadInfoComponent } from '../lead-info/lead-info.component';

@Component({
  selector: 'app-followup-details',
  templateUrl: './followup-details.component.html',
  styleUrls: ['./followup-details.component.css'],
})
export class FollowupDetailsComponent {
  constructor( public router: Router, public cookieService: CookieService,public activatedRoute: ActivatedRoute,private apiservice: ApiservicesService,public dialog: MatDialog) { }

  public progressLoader: boolean = false;
  public tabledatatalist: any = [];
  public header = 'Add Package';
  public userName: any = {};
  public lastLogin: any = {};
  public cookieData: any = {};
  public api_url_for_managebanner = environment.api_url;
  public listprogressBar: any = false;
  public listdataFlag: any = false;
  public paramsId: any = {};
  public now: Date = new Date();
  public currentUnixTimestamp = moment().valueOf();
  public searchCondData: any;
  public newressBar: any = false;
  public editButtonStatus = true;
  public attendButtonStatus = true;
  public loader: boolean = false;  
  public jwttokenformanagebanner = '';
  public buttonFlag: any = 'upcoming';
  datacollection: any = 'followup/follow-list';
  deleteendpoint = 'followup/followup-delete';
  date_search_source_count: any = 0;
  tabledata_header_skip: any = ['_id'];
  updateendpoint = 'package/addupdate-packages';
  date_search_source: any = 'users';
  date_search_endpoint: any = 'intake/assaylist';
  tablename = 'package';
  editroute = 'admin-dashboard/leads/edit-flowup-list';

  modify_header_array: any = {
    addedby_name: 'Added By',
    addedfor_name: 'Added For',
    date: 'Date',
    start_time: 'Time',
    created_on: 'Created On',
  };

  tabledata_detail_skip: any = ['_id'];

  statusarray: any = [{ val: 1, name: 'Active' },{ val: 0, name: 'Inactive' },];
  sourcearr: any = [
    { val: 'Access Plus Underpayment Recovery',name: 'Access Plus Underpayment Recovery'},
    { val: 'Aspire Shipping Solution', name: 'Aspire Shipping Solution' },
    { val: 'Aspire Credit Card Audit', name: 'Aspire Credit Card Audit' },
    { val: 'Practice2lab', name: 'Practice2lab' },
    { val: 'Aspire Commercial Payments', name: 'Aspire Commercial Payments' }
  ];

  search_settings: any = {
    datesearch: [
      {
        startdatelabel: 'Search By Created On Start Date',
        enddatelabel: 'Search By Created On End Date',
        submit: 'Search',
        field: 'created_on',
      }
    ],

    textsearch: [
    ],
    search: [
      {
        label: 'Search By Added By',
        field: 'added_by',
        values: '',
        serversearchdata: { endpoint: 'user/autocomplete-for-followup' },
      },
      {
        label: 'Search By Added For',
        field: 'added_for',
        values: '',
        serversearchdata: { endpoint: 'leads/autocomplete-lead-name' },
      },
    ],

    selectsearch: [],
  };

  searchendpoint = 'leads/lead-list';

  sortdata: any = {
    type: 'asc',
    field: 'start_time_unix',
    options: ['addedby_name','addedfor_name','email','rep_name','status','priority','created_on',],
  };

  limitcond: any = {limit: 10,skip: 0,pagecount: 1,};

  public updatetable: boolean = false;

  libdata: any = {
    basecondition: {},
    detailview_override: [
      { key: 'name', val: 'Name' },
      { key: 'email', val: 'Email' },
      { key: 'phone_number', val: 'Phone No' },
      { key: 'product_name', val: 'Source' },
      { key: 'rep_name', val: 'Associated Rep' },
      { key: 'status', val: 'Status' },
      // { key: "created_on", val: "Created On" },
    ],
    updateendpoint: 'leads/lead-status-change',
    hideeditbutton: false,
    hidedeletebutton: false,
    hideviewbutton: true,
    hidestatustogglebutton: true,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: 'leads/lead-status-change',
    deleteendpointmany: 'followup/followup-delete',

    tableheaders: ['addedby_name','addedfor_name','date','start_time','created_on',],
    colpipes: [{ type: 'datetime', col: 'created_on', format: 'MMMM D YYYY, h:mm A' },],

    custombuttons: [
      {
        label: 'Preview',
        type: 'listner',
        id: 'preview_btn',
        tooltip: 'Preview',
        name: 'preview_btn',
        classname: 'previewButton',
        previewlist: ['added_by','addedby_email','addedfor_name','addedfor_email','notes','created_on','updated_on',],
      },
      {
        label: 'Reschedule',
        type: 'listner',
        id: 'Reschedule',
        tooltip: 'Reschedule',
        name: 'Reschedule',
        classname: 'RescheduleButton',
      },

      {
        label: 'Push schedule',
        type: 'listner',
        id: 'Pushschedule',
        tooltip: 'Push schedule',
        name: 'Pushschedule',
        classname: 'Pushschedule',
      },
      {
        label: 'Update Disposition',
        type: 'listner',
        id: 'disposition_btn',
        tooltip: 'Manage Disposition',
        name: 'disposition_btn',
        classname: 'dispositionButton',
      },
      {
        label: 'Disposition Lifecycle',
        type: 'listner',
        id: 'dispolifecycle_btn',
        tooltip: 'Disposition Lifecycle',
        name: 'dispolifecycle_btn',
        classname: 'dispolifecycleButton',
      },
      {
        label: 'Lead Information',
        type: 'listner',
        id: 'lead_information',
        tooltip: 'Lead Information',
        name: 'lead_information',
        classname: 'lead_information',
        // cond: 'leads_data',
        // condval: true
      }

    ],
  };
  navigateFollowup() {this.router.navigateByUrl('/admin-dashboard/leads/add-flowup-list')}

  ngOnInit() {
    this.paramsId = this.activatedRoute.snapshot.params['id'];

    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }

    this.buttonFlag = 'upcoming';
    this.followupBtnFunction(this.buttonFlag)
    this.tableListData({start_time_unix: { $gte: this.currentUnixTimestamp }, attend: false});
    this.tableListCount({start_time_unix: { $gte: this.currentUnixTimestamp }, attend: false});

    this.libdata.basecondition = {start_time_unix: { $gte: this.currentUnixTimestamp },attend: false};
    this.fetchDisposition()



    if (this.cookieData) {
      this.libdata.notes = {
        label: 'Notes',
        tooltip: 'Add Notes',
        listendpoint: 'notes/notes-list',
        deleteendpoint: 'notes/note-delete',
        addendpoint: 'notes/notes-add',
        extracond: { collection: 'leads' },
        user: this.cookieData.userinfo._id,
        currentuserfullname: this.cookieData.userinfo.name,
        header: 'addedfor_name',
      };
    }
  }

  public dispositionData: any = []

  async fetchDisposition(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.apiservice.getHttpData('disposition/fetch-disposition-list').subscribe((response: any) => {
          this.dispositionData = response.results;
          resolve(true);
        });
    });
  }
  tableListData(val: any) {
    let dataobj = {
      condition: {limit: 10,skip: 0},
      searchcondition: {},
      sort: {type: 'asc',field: 'start_time_unix'},
      project: {},
      token: '',
    };
    dataobj.searchcondition = val;
    this.apiservice.getHttpDataPost('followup/follow-list', dataobj).subscribe((response) => {
        if (response.status == 'success') {
          this.loader = false;
          this.tabledatatalist = response?.results?.res? response?.results?.res: [];
          if (this.tabledatatalist.length > 0) {
            this.listdataFlag = true;
          } else {
            this.listdataFlag = false;
          }
        }
      });
  }


  tableListCount(val: any) {
    let countObj = {
      condition: {limit: 10,skip: 0},
      searchcondition: {},
      sort: {type: 'asc',field: 'start_time_unix'},
      project: {},
      token: '',
    };
    countObj.searchcondition = val;
    this.apiservice.getHttpDataPost('followup/follow-list-count', countObj).subscribe((response: any) => {
        if (response && response.count) {
          this.date_search_source_count = response.count; // role data count  save
        }
      });
  }

  insertElementAfter(valueToFind: any, newElement: any) {
    // Find the index of the object containing the specified value
    const index = this.libdata.custombuttons.findIndex((obj: any) => obj.name === valueToFind);

    // Check if the value was found
    if (index !== -1) {
      // Insert the new element after the found index
      this.libdata.custombuttons.splice(index + 1, 0, newElement);
    } else {
      console.log('Value not found in the array');
    }
  }
  followupBtnFunction(e: any) {
    this.loader = true;

    if (e === 'upcoming') {
      this.libdata.hideeditbutton = false;
      this.attendButtonStatus = true;
      this.buttonFlag = 'upcoming';
      this.libdata.basecondition = {start_time_unix: { $gte: this.currentUnixTimestamp },attend: false};
      let RescheduleButton = {label: 'Reschedule',type: 'listner',id: 'Reschedule',tooltip: 'Reschedule',name: 'Reschedule',classname: 'RescheduleButton'}


      let is_attendbtn_exist_re = this.libdata.custombuttons.find(
        (data: any) => data.label == 'Reschedule'
      );

      !is_attendbtn_exist_re && this.insertElementAfter("preview_btn", RescheduleButton);


      let cusbtn = {
        label: 'Attend',
        type: 'listner',
        id: 'attend_btn',
        tooltip: 'Mark As Attend',
        name: 'attend_btn',
        classname: 'attendButton',
        cond: 'attend',
        condval: false,
      };

      let is_attendbtn_exist = this.libdata.custombuttons.find(
        (data: any) => data.label == 'Attend'
      );

      !is_attendbtn_exist && this.libdata.custombuttons.push(cusbtn);

      ////////////// If there is no data in any of the tab the listing is not updating because the list isnt initialized, section is done to prevent that ////////////////////

      if (this.tabledatatalist.length == 0) {
        this.tableListData({
          attend: false,
          start_time_unix: { $gte: this.currentUnixTimestamp },
        });

        this.tableListCount({
          attend: false,
          start_time_unix: { $gte: this.currentUnixTimestamp },
        });
      } else {
        this.updatetable = !this.updatetable;
      }
      this.loader = false;
    }
    if (e === 'past') {
      this.buttonFlag = 'past';
      this.libdata.hideeditbutton = true;
      this.libdata.basecondition = { attend: true };
      let is_attendbtn_exist = this.libdata.custombuttons.find(
        (data: any) => data.label == 'Attend'
      );
      is_attendbtn_exist && this.libdata.custombuttons.splice(1, 1);

      ////////////// If there is no data in any of the tab the listing is not updating because the list isnt initialized, section is done to prevent that ////////////////////
      if (this.tabledatatalist.length == 0) {
        this.tableListData({ attend: true });
        this.tableListCount({ attend: true });
      } else {
        this.updatetable = !this.updatetable;
      }
      this.loader = false;
    }

    if (e === 'missed') {
      this.attendButtonStatus = true;
      this.libdata.hideeditbutton = true;
      this.buttonFlag = 'missed';
      this.libdata.basecondition = {start_time_unix: { $lt: this.currentUnixTimestamp },attend: false};

      let is_attendbtn_exist = this.libdata.custombuttons.find(
        (data: any) => data.label == 'Attend'
      );

      is_attendbtn_exist && this.libdata.custombuttons.splice(1, 1);

      ////////////// If there is no data in any of the tab the listing is not updating because the list isnt initialized, section is done to prevent that ////////////////////
      if (this.tabledatatalist.length == 0) {
        this.tableListData({attend: false,start_time_unix: { $lt: this.currentUnixTimestamp }});
        this.tableListCount({attend: false,start_time_unix: { $lt: this.currentUnixTimestamp }});
      } else {
        this.updatetable = !this.updatetable;
      }
      this.loader = false;
    }
  }

  // custom buttom click events funtion
  listenLiblistingChange(data: any = null) {


    if ( data.action === 'custombuttonclick' && data.custombuttonclick.btninfo.id === 'attend_btn' && data.custombuttonclick.data) {
      let dilogClose = this.dialog.open(AttendComponent, {
        data: {
          data: data.custombuttonclick.data,
          followFormStatus: this.updatetable,
        },
      });

      dilogClose.afterClosed().subscribe((result) => {
        this.followupBtnFunction('upcoming');
      });
    }

    
    if (data.action === 'custombuttonclick' &&
      data.custombuttonclick.btninfo.id === 'disposition_btn' && data?.custombuttonclick?.btninfo.id == 'disposition_btn') {

      const dialogRef = this.dialog.open(DispositionModalNew, {
        panelClass: ['custom-modalbox', 'folderviewModal'],
        data: {
          heading: 'Folder View Data',
          dispositionObj: data.custombuttonclick.data,
          data: this.dispositionData,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.updatetable = !this.updatetable;
      });

    }

    if (data.action === 'custombuttonclick' &&
      data.custombuttonclick.btninfo.id === 'dispolifecycle_btn' && data?.custombuttonclick?.btninfo.id == 'dispolifecycle_btn') {

      const dialogRef = this.dialog.open(LifecycleModalNew, {
        panelClass: 'custom-modalbox',
        data: {
          heading: 'Disposition Lifecycle',
          lifecycleObj: {
            for_disposition: true,
            lead_id: data.custombuttonclick?.data?.leads_data[0]?._id,
          },
        },
      });
    }

    if (data.action === 'custombuttonclick' && data.custombuttonclick.btninfo.id === 'preview_btn' && data.custombuttonclick.data) {
      this.dialog.open(PreviewComponent, {
        data: {
          key: data.custombuttonclick.btninfo.previewlist,
          value: data.custombuttonclick.data,
        },
      });
    }

    if (data.action === 'custombuttonclick' && data.custombuttonclick.btninfo.id === 'Reschedule' && data.custombuttonclick.data) {
      let dilogClose = this.dialog.open(RescheduleFollowupComponent, {
        data: {
          data: data.custombuttonclick.data,
          followFormStatus: this.updatetable,
        },
      });

      dilogClose.afterClosed().subscribe((result) => {
        this.followupBtnFunction('upcoming');
      });
    }

    if (data.action === 'custombuttonclick' && data.custombuttonclick.btninfo.id === 'Pushschedule' && data.custombuttonclick.data) {
      let dilogClose = this.dialog.open(RescheduleFollowupComponent, {
        data: {
          data: data.custombuttonclick.data,
          followFormStatus: this.updatetable,
          pushsection: true
        },
      });

      dilogClose.afterClosed().subscribe((result) => {
        this.followupBtnFunction('upcoming');
      });
    }














    if ( data.action === 'custombuttonclick' && data.custombuttonclick.btninfo.id === 'lead_information' && data.custombuttonclick.data) {





      this.dialog.open(LeadInfoComponent, {
        data: {
          value: data.custombuttonclick.data,
        },
      });
      



    }

















  }

  onLiblistingButtonChange(data: any = null) {
    console.log('onLiblistingButtonChange', data);
  }
}

@Component({
  selector: 'disposition-modalnew',
  templateUrl: 'disposition-modalnew.html',

})
export class DispositionModalNew {
  public updatetable: boolean = false;
  public roleList: any = [];
  public formfieldrefreshdata: any = null;
  public modaldata: any = {};
  public loader: boolean = false;
  public cookieData: any = {};
  public userformdata: any = {};
  public editFormData: any = null;
  public formfieldrefresh: any = null;
  public update_id: any = null;
  public forUpdate: boolean = false;
  public formLoader: boolean = false;
  public formValue: any = null;
  public submitBody: any;
  public noteInputValue: any = '';
  public colorValue: any;
  public showNoteAdd: boolean;

  pic_color: any = '';

  constructor(
    public dialogRef: MatDialogRef<DispositionModal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public router: Router,
    private apiservice: ApiservicesService,
    public matSnackBar: MatSnackBar,
    public cookieService: CookieService,
    public dialog: MatDialog
  ) {
    this.modaldata = this.data;

    console.log("data.custombuttonclick.data", this.modaldata);

  }

  ngOnInit() {
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(
        this.cookieService.getAll()['login_user_details']
      );
    }
    this.initailForm();
  }

  public closeModal() {
    this.dialogRef.close();
  }

  initailForm() {
    this.userformdata = {
      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: 'Submit',
      apiUrl: this.apiservice.baseUrl,
      resettext: 'Reset',
      canceltext: 'Cancel',
      hidereset: this.editFormData ? true : false,
      jwttoken: '',
      fields: [
        {
          label: 'Disposition',
          name: 'disposition',
          type: 'select',
          val: this.modaldata.data,
          value: this.modaldata.dispositionObj?.leads_data[0].disposition,
        },
      ],
    };
  }

  getNoteValue(event: any) {
    this.noteInputValue = event.target.value;
  }

  onNoteSubmitClick() {
    this.submitDisposition({ ...this.submitBody, note: this.noteInputValue });
  }

  listenFormFieldChange(val: any) {
    if (val.field == 'fromsubmit') {
      let dispoFilter = this.modaldata.data.find((e: any) => e.key == val.fromval.disposition);
      if (dispoFilter.notes_needed) {
        this.showNoteAdd = true;
        this.submitBody = {
          _id: this.modaldata.dispositionObj?.leads_data[0]._id,
          disposition: val.fromval.disposition,
          user_id: this.cookieData?.userinfo?._id,
        };
      } else {
        this.submitDisposition({
          _id: this.modaldata.dispositionObj?.leads_data[0]._id,
          disposition: val.fromval.disposition,
          user_id: this.cookieData?.userinfo?._id,
        });
      }
    }

    if (val.field == 'formcancel') {
      this.dialogRef.close();
    }
  }

  submitDisposition(val: any) {
    this.loader = true;
    this.apiservice.getHttpDataPost('leads/update-disposition', val).subscribe({
      next: (reponse) => {
        if (reponse.status == 'success') {
          this.matSnackBar.open('Disposition updated successfully', 'ok', {
            duration: 5000,
          });
          this.dialogRef.close();
        }
        this.loader = false;
      },
      error: (error) => {
        this.matSnackBar.open(error.message, 'ok', {
          duration: 5000,
        });
        this.loader = false;
      },
    });
  }

  // this.formfieldrefreshdata = {}
}



@Component({
  selector: 'LifecycleModal',
  templateUrl: 'lifecycle-modalNew.html',

})
export class LifecycleModalNew {
  public lifecycleValue: any = {};
  public isDispoLifeCycle: boolean = false;

  constructor(
    public apiService: ApiservicesService,
    public dialogRef: MatDialogRef<LifecycleModalNew>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public matSnackBar: MatSnackBar
  ) {
    this.lifecycleValue = data;
    console.log("okkjkj", this.lifecycleValue);

  }

  public lifeCycleData: any = [];
  public inHtmlDispo: boolean = false;
  public loader: boolean = false;

  ngOnInit() {
    this.isDispoLifeCycle = this.lifecycleValue.lifecycleObj.for_disposition
      ? true
      : false;

    console.log(this.isDispoLifeCycle);

    if (this.isDispoLifeCycle) {
      this.inHtmlDispo = true;
      this.loader = true;

      this.apiService
        .getHttpDataPost('disposition/fetch-disposition-lifecycle', {
          id: this.lifecycleValue.lifecycleObj.lead_id,
        })
        .subscribe({
          next: (response: any) => {
            if (response && response.results.length > 0) {
              response.results.forEach((e: any) => {
                let dataObj = {
                  val: e._id,
                  name: e.disposition_name,
                  username: e.user_name,
                  datetime: moment(e.created_on).format('MMMM Do YYYY'),
                };

                this.lifeCycleData.push(dataObj);
              });
            }
            this.loader = false;
          },
          error: (error: any) => {
            this.loader = false;
          },
        });
    } else {
      this.loader = true;
      this.apiService
        .getHttpDataPost('leads/fetch-owner-lifecycle', {
          id: this.lifecycleValue.lifecycleObj.lead_id,
        })
        .subscribe({
          next: (response: any) => {
            if (response && response.results.length > 0) {
              response.results.forEach((e: any) => {
                let dataObj = {
                  val: e._id,
                  name: e.owner_name,
                  username: e.transfer_by_username,
                  datetime: moment(e.created_on).format('MMMM Do YYYY'),
                };

                this.lifeCycleData.push(dataObj);
              });
            }
            this.loader = false;
          },
          error: (error: any) => {
            this.loader = false;
          },
        });
    }
  }

  public closeModal() {
    this.dialogRef.close();
  }
}