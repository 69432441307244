import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { NavigationEnd, NavigationError, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public baseUrl: any = environment.api_url;
  title = 'betoparedes-v3';
  apiUrl = this.baseUrl +'user/last-rep-user-id';
  apiData: any;
  constructor(public router: Router,private http: HttpClient) {
    // console.log("environment========>", environment)
  }
  color: ThemePalette = 'accent';
  mode: ProgressBarMode = 'indeterminate';
  public loader: boolean = false;

  ngOnInit() {
    // Loader Config
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart:
          this.loader = true;
          break;
        case event instanceof NavigationEnd:
          this.loader = false;
          break;
        case event instanceof NavigationError:
          this.loader = false;
          break;
      }
    });

    // Call API initially
    this.callApi();

    // Set up interval to call API every 15 seconds
    setInterval(() => {
      this.callApi();
    }, 20000); 
  }
  callApi() {
    this.http.get(this.apiUrl).subscribe(
      (response) => {
        this.apiData = response; 
        // Do something with the API response (e.g., update a variable, display it in the template)
      },
      (error) => {
        console.error('Error fetching API data:', error);
      }
    );
  }
}


