import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { ApiservicesService } from '../services/apiservices.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { OtpVerificationComponent } from '../otp-verification/otp-verification.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  public loginForm: FormGroup;
  public loader: boolean = false;
  public not_Varifed: boolean = false
  private unsubscriber: Subject<void> = new Subject<void>();
  public errors: any
  public loginButtonStatus: boolean = false
  public lastRoute: any = ""
  hide = true;



  constructor(private apiService: ApiservicesService, private cookieService: CookieService, public router: Router, public matSnackBar: MatSnackBar, public dialog: MatDialog) {

    this.loginForm = new FormGroup({email: new FormControl(''),password: new FormControl(''),})

  }



  ngOnInit() {

    if(this.lastRoute == "/admin-dashboard/contract-management/request-contracts-list") {
      sessionStorage.setItem("lastroute", this.lastRoute);
    }
    history.pushState(null, '');
    this.apiService.getHttpData('user/build-connection').subscribe({
      next: (getIpResp) => {console.log("Database Connected");}
    })

    fromEvent(window, 'popstate').pipe(takeUntil(this.unsubscriber)).subscribe((_) => {
      history.pushState(null, '');
    });

    this.demoFuntionCall()

  }





  demoFuntionCall(){
    this.apiService.getHttpData("user/demo-api").subscribe({
      next: (resp) => {
        console.log("demofuntion to call damo api",resp);
        
      }
    })
  }


  handleInputChange(data: any) {
    
    if (data.target.name === "email") {
      this.errors = { ...this.errors, email: '' }
    }
    if (data.target.name === "password") {
      this.errors = { ...this.errors, password: '' }
    }
    if (data.key === "Enter") {
      this.login()
    }
  }

  async getIpInfo(): Promise<any> {
    try {
      const response = await fetch('https://ipinfo.io/json?token=32b3c233366230');
      const data = await response.json();
      return data;  // This would return an object like { ip: "xxx.xxx.xxx.xxx" }
    } catch (error) {
      console.error("Error fetching IP info:", error);
      return null;
    }
  }


  async login() {

    if (this.loginForm.value.email === "") {
      this.errors = { ...this.errors, email: "Email is required" }
    }
    if (this.loginForm.value.password === "") {
      this.errors = { ...this.errors, password: "Password in required" }
    }

    else {
      this.loader = true;
      console.log("this.loginForm.value", this.loginForm.value);

      const ipInfo = await this.getIpInfo();

      const loginPayload = {
        ...this.loginForm.value,
        ipInfo: ipInfo || {}, 
      };

      console.log("loginPayload", loginPayload);
      
      this.apiService.getHttpDataPost(environment.stage == "local" ? "user/login" : "user/login-new", loginPayload).subscribe({
        next: (response: any) => {
          if (response.status === "success") {
            if(response.isOffline) {
              this.loader = false;
              this.matSnackBar.open("Come to our September 23rd, 2024 sales program kick off!", "", {
              duration: 5000
            })
            
          } else {
            const expirationDate = new Date();
            expirationDate.setMinutes(expirationDate.getMinutes() + 60);
            let obj = {...response.results}
            obj['expires_time'] = expirationDate
            
            this.cookieService.set('login_user_details', JSON.stringify(obj), { path: '/', expires: expirationDate });
            this.cookieService.set('loggedin_user', 'true', { path: '/', });
            this.loader = false;
            this.matSnackBar.open(response.message, "Ok", {
              duration: 3000
            })


            if (response.results.userinfo.user_type === 'is_admin') {
              if(sessionStorage.getItem('lastroute')) {
                let lastRoute = sessionStorage.getItem('lastroute')
                this.router.navigateByUrl(`${lastRoute}`);
                setTimeout(() => {
                  sessionStorage.removeItem('lastroute')
                }, 1500);
              } else {
                this.router.navigateByUrl(`/admin-dashboard`);
              }
            }

            if (response.results.userinfo.user_type === 'is_rep') {
              if (response.results.userinfo.is_rep_contract_signed == 0 || !response.results.userinfo.is_rep_contract_signed) {
                if(response.results.userinfo?.is_affiliate !=null && response.results.userinfo?.is_affiliate == 0)
                this.router.navigateByUrl(`/rep-dashboard/affiliate-sign-contract/`+response.results.userinfo?._id);
              else 
                this.router.navigateByUrl(`/rep-dashboard/sign-contract`);
              } else if (response.results.userinfo.is_w9_form_signed == 0) {
                this.router.navigateByUrl(`/rep-dashboard/w9-form`);
              } else if (response.results.userinfo.has_betoparedes_access == 1 || response.results.userinfo.has_betoparedes_access == 2) {
              if(response.results.userinfo.unique_identifier && response.results.userinfo.RequiredPercentage != 100 && response.results.userinfo.percentage != 100) {
                this.router.navigateByUrl(`/trainings`);
              } else {
                this.router.navigateByUrl(`/rep-dashboard`);
              }
              }
            }
          }
          } else {
            this.matSnackBar.open("Something went wrong!!", "", {
              duration: 3000
            })
          }
        },


        error: (error: any) => {
          if (error.results?.user === "inactiv") {
            this.matSnackBar.open(error.results.message, "", {duration: 3000})
          } else {
            this.matSnackBar.open(error.message, "", {duration: 3000})
          }
          this.loader = false;

          if (error.not_Varifed === true) {
            this.cookieService.set('emailverificationcodesend',this.loginForm.value.email , 0.00139);
            const dialogRef = this.dialog.open(OtpVerificationComponent, {
              data: { "not_Varifed": error.not_Varifed, "email": this.loginForm.value.email.split(" ").join(""), login: this.login },
            })
            this.loginButtonStatus = true
            dialogRef.afterClosed().subscribe((result) => {
              if(result != 'cancel'){
                this.login()
                this.loginButtonStatus = false
              }
            })
          }
        }
      })





    }







  }





  handlenter(e: any) {
    this.hide = true
    console.log("enter click", e.key);

    if (e.key === "Enter") {
      this.login()
    }

  }

  handlevisibl(event: any) {
    event.preventDefault()
    this.hide = !this.hide
  }


}


