<div class="top-header">
  <div class="left-menu">
    <div class="logo-wrapper">
      <div class="logo-sub-wrapper">
        <img src="https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/home/Beto_Logo.png" alt="" />
      </div>
    </div>
  </div>
  <div class="right-menu">


    <div class="top-btn user">
      <i class="fa fa-user-circle-o" aria-hidden="true"></i>
    </div>

    <h3 style="text-transform: capitalize;">{{login_user_details.userinfo.name}}</h3>

    <div class="top-btn" [matMenuTriggerFor]="rightdownmenu">
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </div>

    <mat-menu #rightdownmenu="matMenu">
      <button *ngIf="!(this.forTrainings || this.rep_contract || this.w9_form_contract || this.affiliate_contract)" mat-menu-item (click)="myAccount()">
        <i class="fa fa-user-circle-o" aria-hidden="true"></i> My Account
      </button>
      <button *ngIf="!(this.forTrainings || this.rep_contract || this.w9_form_contract || this.affiliate_contract)" mat-menu-item (click)="changePass()">
        <i class="fa fa-unlock-alt" aria-hidden="true"></i> Change Password
      </button>

      <button mat-menu-item (click)="logout()">
        <i class="fa fa-sign-out" aria-hidden="true"></i> Logout
      </button>

    </mat-menu>
  </div>
</div>

<div *ngIf="!(this.forTrainings || this.rep_contract || this.w9_form_contract || this.affiliate_contract)" class="header_mainwrapper">
  <div class="header_subwrapper">
    <div class="menu-wrapper" [class.showmenu]="classToggled">
      <ul>
        <!-- Dashboard Navigation -->
        <li routerLinkActive="active">
          <a [ngClass]=" currentPath() == '/admin-dashboard' || currentPath() == '/rep-dashboard' || currentPath() == '/customer-dashboard' ? 'active' : '' " (click)="dashboardPage()">Dashboard</a>
        </li>
        <!-- User List Navigation -->
        <li *ngIf="adminFlag">
          <a [ngClass]=" currentPath() == '/admin-dashboard/user' ? 'active' : '' || currentPath() == '/rep-dashboard/user-list' ? 'active' : ''  " (click)="repPath()">Users</a>
        </li>




        <li (click)=" navigateToPath('admin-dashboard/leads/leads-in-system') " (click)="leadsPath('leads-in-system')">
          <a  [ngClass]="currentPath() == '/admin-dashboard/leads/leads-in-system' || currentPath() == '/admin-dashboard/leads/leads-outside-system' ? 'active' : ''|| currentPath() == '/rep-dashboard/leads/leads-in-system' || currentPath() == '/rep-dashboard/leads/leads-outside-system' ? 'active' : ''">
            Leads 
          </a>
        </li>


        <ng-container *ngIf="login_user_details?.userinfo?._id=='66f41af1c10e1314ac286a99'"> 
          <li (click)="
          navigateToPath('rep-dashboard/leads/leads-in-medical-lab')
        " (click)="leadsPath('leads-in-medical-lab')">
            <a  [ngClass]="
                currentPath() == '/admin-dashboard/leads/leads-in-medical-lab' || currentPath() == '/admin-dashboard/leads/leads-in-medical-lab' ? 'active' : ''||
                currentPath() == '/rep-dashboard/leads/leads-in-medical-lab' || currentPath() == '/rep-dashboard/leads/leads-in-medical-lab' ? 'active' : ''
              ">Laboratory & Medical Facility
              <!-- <i class="fa-solid fa-circle-chevron-down"></i> -->
            </a>
          </li>
        </ng-container>

 

        <li *ngIf="adminFlag">
          <a [ngClass]="currentPath() == '/admin-dashboard/miscellaneous/followup' ? 'active' : '' " (click)="navigateToPath('/admin-dashboard/miscellaneous/followup')">Follow Ups</a>
        </li>




        <!-- <li>
          <a [matMenuTriggerFor]="Report" [ngClass]=" currentPath() == '/rep-dashboard/clickreport' || currentPath() == '/rep-dashboard/conversion-report' || currentPath() == '/admin-dashboard/report/conversion-report' || currentPath() == '/admin-dashboard/training/training-category' || currentPath() == '/admin-dashboard/report/click-report' ? 'active' : ''">
            Report 
            <i class="fa-solid fa-circle-chevron-down"></i>
          </a>
        </li>


        <mat-menu #Report="matMenu">
          <button mat-menu-item (click)="conversionReportClick()">Conversion Report</button>
          <button mat-menu-item (click)=" clickReport()">Click Report</button>
        </mat-menu> -->

        <li *ngIf="repFlag">
          <a [ngClass]=" currentPath() == '/rep-dashboard/calendar-management' ? 'active' : ''  " (click)="calendarbuttonClick()">Calendar</a>
        </li>

        <!-- <li *ngIf="repFlag" [matMenuTriggerFor]="calender_book">
          <a>Meetings <i class="fa-solid fa-circle-chevron-down"></i></a>
        </li>
        <mat-menu #calender_book="matMenu" style="max-width:290px">
          <button mat-menu-item (click)="choseProduct('nethun')">
            Book a Gameplan Call with Beto Paredes
          </button>
          <button mat-menu-item (click)="choseProduct('beto')">
            Book a Discovery Call For a Potential Client
          </button>
        </mat-menu> -->

        <li *ngIf="adminFlag">
          <a [ngClass]="currentPath() == '/admin-dashboard/campaign' ? 'active' : ''" (click)="campaignPath()">Campaign</a>
        </li>


        <li *ngIf="adminFlag">
          <a [ngClass]="currentPath() == '/admin-dashboard/contract-management/request-contracts-list' ? 'active' : ''" (click)="navigateToPath('/admin-dashboard/contract-management/request-contracts-list')">Requested Contract List</a>
        </li>


        <li *ngIf="!repFlag">
          <a [matMenuTriggerFor]="miscellaneous"
            [ngClass]="currentPath() == '' || currentPath() == '/admin-dashboard/miscellaneous/banner' || currentPath() == '/admin-dashboard/opportunity/product-category' || currentPath() == '/admin-dashboard/opportunity/product-management' || currentPath() == '/admin-dashboard/disposition' || currentPath() == '/admin-dashboard/files/files-category' || currentPath() == '/admin-dashboard/files/files-managment' || currentPath() == '/admin-dashboard/video/video-category' || currentPath() == '/admin-dashboard/video/video-managment' || currentPath() == '/admin-dashboard/invoice-list' || currentPath() == '/admin-dashboard/contract-management/contract-list' || currentPath() == '/admin-dashboard/contract-management/sent-contracts-list' || currentPath() == '/admin-dashboard/slug-list' || currentPath() == '/admin-dashboard/email-template-management' || currentPath() == '/admin-dashboard/webinar-management/webinar-list' || currentPath() == '/rep-dashboard/asset-menu' || currentPath() == '/rep-dashboard/trainings' || currentPath() == '/rep-dashboard/contract-status-list' || currentPath() == '/admin-dashboard/miscellaneous/failed-login-list'  ? 'active' : '' ">Miscellaneous
            <i class="fa-solid fa-circle-chevron-down"></i></a>
        </li>

        <mat-menu #miscellaneous="matMenu">
          <button *ngIf="adminFlag" mat-menu-item (click)=" navigateToPath('/admin-dashboard/miscellaneous/banner')">
            Banner Management
          </button>
          <button *ngIf="adminFlag" mat-menu-item (click)="navigateToPath('/admin-dashboard/miscellaneous/followup')">
            Follow Ups
          </button>

          <button *ngIf="adminFlag" mat-menu-item [matMenuTriggerFor]="Opportunities" [ngClass]="currentPath() == '' || currentPath() == '/admin-dashboard/opportunity/product-category' || currentPath() == '/admin-dashboard/opportunity/product-management' ? 'active'  : '' ">
            Opportunities
          </button>

          <mat-menu #Opportunities="matMenu">
            <button mat-menu-item (click)="navigateToPath('admin-dashboard/opportunity/product-category')">
              Opportunities Category
            </button>
            <button mat-menu-item (click)="navigateToPath('admin-dashboard/opportunity/product-management')">
              Opportunities Management
            </button>
          </mat-menu>



          <button *ngIf="adminFlag" mat-menu-item [matMenuTriggerFor]="Report" [ngClass]="currentPath() == '' || currentPath() == '/admin-dashboard/opportunity/product-category' || currentPath() == '/admin-dashboard/opportunity/product-management' ? 'active'  : '' ">
            Report
          </button>

          <mat-menu #Report="matMenu">
            <button mat-menu-item (click)="conversionReportClick()">
              Conversion Report
            </button>

            <button mat-menu-item (click)="clickReport()">
              Click Report
            </button>

          </mat-menu>











          <button *ngIf="adminFlag" mat-menu-item [matMenuTriggerFor]="Files" [ngClass]="currentPath() == '' || currentPath() == '/admin-dashboard/training/training-category' || currentPath() == '/admin-dashboard/files/files-managment' || currentPath() == '/admin-dashboard/files/files-category'  ? 'active'  : '' ">
            Files
          </button>

          <mat-menu #Files="matMenu">
            <button mat-menu-item (click)=" navigateToPath('/admin-dashboard/files/files-category')">
              Files Category
            </button>
            <button mat-menu-item (click)="navigateToPath('admin-dashboard/files/files-managment')">
              Files Management
            </button>
          </mat-menu>

          <button *ngIf="adminFlag" mat-menu-item [matMenuTriggerFor]="Video">
            Videos
          </button>

          <mat-menu #Video="matMenu">
            <button mat-menu-item (click)="navigateToPath('admin-dashboard/video/video-category')">
              Video Category
            </button>
            <button mat-menu-item (click)="navigateToPath('admin-dashboard/video/video-managment')">
              Video Management
            </button>
          </mat-menu>

          <button *ngIf="adminFlag" mat-menu-item (click)=" navigateToPath('/admin-dashboard/disposition')">Disposition</button>

          <button *ngIf="adminFlag" mat-menu-item (click)=" navigateToPath('/admin-dashboard/invoice-list')">Invoice
            management</button>

          <button *ngIf="adminFlag" mat-menu-item (click)="navigateToPath('/admin-dashboard/contract-management/contract-list')">
            Contract Management
          </button>
          <button *ngIf="adminFlag" mat-menu-item (click)=" navigateToPath('/admin-dashboard/contract-management/sent-contracts-list')">
            Sent Contracts List
          </button>
          <!-- <button *ngIf="adminFlag" mat-menu-item (click)="navigateToPath('/admin-dashboard/contract-management/request-contracts-list')">
            Request Contracts List
          </button> -->
          <button *ngIf="adminFlag" mat-menu-item (click)="navigateToPath('/admin-dashboard/slug-list')">
            Slug Management
          </button>
          <button *ngIf="adminFlag" mat-menu-item (click)="navigateToPath('/admin-dashboard/email-template-management')">
            Email Template
          </button>
          <button *ngIf="adminFlag" mat-menu-item (click)="navigateToPath('/admin-dashboard/miscellaneous/failed-login-list')">
            Failed Login Attempts
          </button>


          <button *ngIf="repFlag" mat-menu-item (click)="navigateToPath('/rep-dashboard/asset-menu')">
            Opportunity Assets
          </button>



        </mat-menu>




        <li *ngIf="repFlag">
          <a [ngClass]="currentPath() == '/trainings' ? 'active' : ''" (click)="navigateToPath('/training-video')">Trainings</a>
        </li>

        <li *ngIf="repFlag">
          <a [ngClass]="currentPath() == '/contract-status-list' ? 'active' : ''" (click)="navigateToPath('/rep-dashboard/contract-status-list')">Contracts</a>
        </li>


        <li *ngIf="adminFlag && environmentany">
          <a [ngClass]="currentPath() == '/presentation-center' ? 'active' : ''" (click)="presentaionPath()">Presentation Center</a>
        </li>

        <li *ngIf="adminFlag">
          <a [ngClass]="currentPath() == '/admin-dashboard/show_rep_wise_booking' ? 'active' : ''" (click)="showbookingRoute()">Booking</a>
        </li>


        <li *ngIf="is_affiliate==1">
          <a [ngClass]="currentPath() == '/rep-dashboard/associate' ? 'active' : ''" (click)="gotoAssociate()">Associates</a>
        </li>



      </ul>
    </div>
    <div (click)="toggleNav()" class="toggleBtn">
      <i class="fa fa-bars" aria-hidden="true"></i>
    </div>
  </div>
</div>
<ng-container *ngIf="navbuttonLoader">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-container>
