import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { LeadPreviewComponent } from 'src/app/Common-components/lead-preview/lead-preview.component';

@Component({
  selector: 'app-lead-info',
  templateUrl: './lead-info.component.html',
  styleUrls: ['./lead-info.component.css']
})
export class LeadInfoComponent {

  public datakey: any;
  public datavalue: any;
  public value: any;
  public extractedData: any = {};
  public created_on: any;
  public updated_on: any
  public created_at :any


  public leaddata:any={}
  public repdata:any = {}


  public haveLead:boolean = false;
  public haveRep:boolean = false;



  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<LeadInfoComponent>) {

    this.datavalue = this.data.value

    console.log("klhhkjhu",this.datavalue?.leads_data.length);
    console.log("klhhkjhu",this.datavalue?.rep_data.length);
    if(this.datavalue?.leads_data.length > 0){
      this.haveLead = true;
      this.leaddata = this.datavalue.leads_data[0]
    }
    if(this.datavalue?.rep_data.length > 0){
      this.haveRep = true;
      this.repdata = this.datavalue.rep_data[0]
    }


    

  }



  ngOnInit() {
    // this.datafilter(this.datavalue)

  }


  datafilter( dataset: any) {

    const keys = Object.keys(dataset)



    for (const key of this.datakey) {

      if (key === "created_on") {
        this.created_on = moment(dataset[key]).format('MMMM Do YYYY, h:mm:ss a')
      }
      if (key === "updated_on") {
        this.updated_on = moment(dataset[key]).format('MMMM Do YYYY, h:mm:ss a')
      }
      if (key === "created_at") {
        this.created_at = moment(dataset[key]).format('MMMM Do YYYY, h:mm:ss a')
      }




      console.log("this.datakey=====>", this.datakey);


    }





  }

  closePreview() {
    this.dialogRef.close()
  }
}
