import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';
import { PreviewComponentsComponent } from './Common-components/preview-components/preview-components.component';
import { SharedModuleModule } from './shared-module/shared-module.module';
import { OtpVerificationComponent } from './otp-verification/otp-verification.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WrongPageComponent } from './wrong-page/wrong-page.component';
import { DeleteComponentComponent } from './Common-components/delete-component/delete-component.component';
import { PieModalComponent } from './Common-components/pie-modal/pie-modal.component';
import { LeadPreviewComponent } from './Common-components/lead-preview/lead-preview.component';
import { CustomPreviewComponent } from './Common-components/custom-preview/custom-preview.component';
import { ShowContractComponent } from './Common-components/show-contract/show-contract.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { RedirectToNdaComponent } from './redirect-to-nda/redirect-to-nda.component';
import { FeatherComponentComponent } from './feather-component/feather-component.component';
import { AddEditAttendeeCommponentComponent } from './add-edit-attendee-commponent/add-edit-attendee-commponent.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { TrainingComponent } from './training/training.component';
import { LeadEventsListComponent } from './admin-dashboard/lead-events-list/lead-events-list.component';
// import { LedStatusComponent } from './led-status/led-status.component';
// import { MaxlenghtDirective } from './maxlenght/maxlenght.directive';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PreviewComponentsComponent,
    OtpVerificationComponent,
    WrongPageComponent,
    DeleteComponentComponent,
    PieModalComponent,
    LeadPreviewComponent,
    CustomPreviewComponent,
    ShowContractComponent,
    SafeUrlPipe,
    RedirectToNdaComponent,
    FeatherComponentComponent,
    AddEditAttendeeCommponentComponent,
    TrainingComponent,
    
    // LedStatusComponent,
    // MaxlenghtDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    SharedModuleModule,
    FormsModule,
    CKEditorModule,
    LeadEventsListComponent,
    ReactiveFormsModule,
  ],
  providers: [AuthGuardService, AuthService],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})
export class AppModule { }
