import { DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Event, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { FeatherComponentComponent } from 'src/app/feather-component/feather-component.component';
import { ApiservicesService } from 'src/app/services/apiservices.service';
import { environment } from 'src/environments/environment';
// import { ApiservicesService } from 'src/app/services/apiservices.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  public cookieData: any = {};
  public userName: any = {};
  public lastLogin: any = {};
  classToggled = false;
  public activemenu: any = '';
  public login_user_details: any = {}
  public textLoader: boolean = false
  public adminFlag: boolean = false
  public repFlag: boolean = false
  public frontendurl = environment.frontendurl
  public cookiedata: any = JSON.parse(this.cookieService.getAll()['login_user_details']);
  public environmentany: any = null;
  public is_affiliate :any=0;


  public navbuttonLoader:boolean= false

  public forTrainings: boolean = false
  public rep_contract:boolean = false
  public w9_form_contract:boolean = false
  public affiliate_contract:boolean = false

  public toggleNav() {
    this.classToggled = !this.classToggled;
  }

  constructor(public router: Router, public activateRoute: ActivatedRoute, private cookieService: CookieService, public matSnackBar: MatSnackBar, private apiservice: ApiservicesService,public dialog: MatDialog) {
    window.scroll(0, 0);
  }
  ngOnInit() {
    
    if (environment.stage === "dev") {
      // console.log("environmentanyenvironmentany", environment.stage);
      
      this.environmentany = environment.stage
    }


    this.login_user_details = JSON.parse(this.cookieService.get('login_user_details'))
    if (this.login_user_details.userinfo.is_affiliate === 1) 
      this.is_affiliate=1;


    if (this.login_user_details.userinfo.user_type === "is_admin") {
      this.adminFlag = true
    } else {
      this.repFlag = true
    }


    if (this.router.url.includes('dashboard')) this.activemenu = 'dashboard';

    if (this.router.url.includes('trainings')) this.forTrainings = true;
    if (this.router.url.includes('sign-contract')) this.rep_contract = true;
    if (this.router.url.includes('w9-form')) this.w9_form_contract = true;
    if (this.router.url.includes('affiliate-sign-contract')) this.affiliate_contract = true;

    if(this.repFlag && this.login_user_details.userinfo.unique_identifier && (this.login_user_details.userinfo.RequiredPercentage != 100 && this.login_user_details.userinfo.percentage != 100 )) {
      console.log("inside header");
      
      this.router.navigateByUrl('/trainings')
    }
  }


  getCookieByName(name:string) {
    const value = document.cookie;
    if (value.includes(name)) {
        console.log("value====>", value)
        let userVal = value.split(`${name}=`)[1]?.split(';')[0]
        console.log("userVal=====>", userVal)
        if (userVal) {
            return userVal
        } else {
            return null
        }
    } else {
        return null
    }
}

  public logout() {
    this.apiservice.getHttpData(`user/logout/${this.login_user_details.userinfo.email}`).subscribe({
      next: (response: any) => {
        if (response.status === "success") {

          this.cookieService.deleteAll('login_user_details')
          this.cookieService.deleteAll('loggedin_user')
          this.cookieService.deleteAll('update_google_detals')


          let cookieVal = this.getCookieByName('login_user_details')
          if(cookieVal){
            if(cookieVal.includes('userinfo')){
              document.cookie = `login_user_details=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
              document.cookie = `loggedin_user=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
              document.cookie = `update_google_detals=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`

            }
          }
          
          this.matSnackBar.open("Logout Successfully", "Ok", {
            duration: 3000
          });
          this.router.navigateByUrl('/')

        }
      },
      error:(error)=>{
        console.log("error======>",error);
        
        this.cookieService.deleteAll('login_user_details')
        this.cookieService.deleteAll('loggedin_user')
        this.cookieService.deleteAll('update_google_detals')
        let cookieVal = this.getCookieByName('login_user_details')
        if(cookieVal){
          if(cookieVal.includes('userinfo')){
            document.cookie = `login_user_details=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
            document.cookie = `loggedin_user=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
            document.cookie = `update_google_detals=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`

          }
        }
      }
    })
  }


  public changePass() {
    this.apiservice.getHttpDataPost("user/send-change-password-otp", { email: this.login_user_details.userinfo.email }).subscribe({
      next: (response: any) => {
        if (response.status === "success") {
          this.matSnackBar.open(response.message, "ok", {
            duration: 3000
          })
          this.router.navigateByUrl(`/reset-password`)
        }
      }
    })


  }


  public myAccount(){
    this.router.navigateByUrl(`/my-account/account-info/${this.login_user_details.userinfo._id}`); 
    
  }
  dashboardPage() {
    if (this.login_user_details.userinfo.user_type === "is_admin")
      this.router.navigateByUrl(`/admin-dashboard`);
    else this.router.navigateByUrl(`/rep-dashboard`);
  }
  repPath() {
    if (this.login_user_details.userinfo.user_type === "is_admin")
      this.router.navigateByUrl(`/admin-dashboard/user`);
    else if (this.login_user_details == 'rep') this.router.navigateByUrl(`/rep-dashboard/user-list`);
  }



  leadsPath(val: string) {
    if (this.login_user_details.userinfo.user_type === "is_admin")
      val == 'leads-in-system' ? this.router.navigateByUrl(`/admin-dashboard/leads/leads-in-system`) : val == 'leads-outside-system' ? `/admin-dashboard/leads/leads-outside-system` : '';
    else {
      val == 'leads-in-system' ? this.router.navigateByUrl(`/rep-dashboard/leads/leads-in-system`) : val == 'leads-outside-system' ? this.router.navigateByUrl(`/rep-dashboard/leads/leads-outside-system`) : '';
    } 
  }
  campaignPath() {
    if (this.login_user_details.userinfo.user_type === "is_admin")
      this.router.navigateByUrl(`/admin-dashboard/campaign`);
    else {
      this.router.navigateByUrl(`/rep-dashboard/campaign`);
    } 
  }
  
  clickReport() {

    if (this.login_user_details.userinfo.user_type === "is_admin")
      this.router.navigateByUrl(`/admin-dashboard/report/click-report`);
    else this.router.navigateByUrl(`/rep-dashboard/clickreport`);


  }
  conversionReportClick() {
    if (this.login_user_details.userinfo.user_type === "is_admin")
      this.router.navigateByUrl(`/admin-dashboard/report/conversion-report`);
    else  this.router.navigateByUrl(`/rep-dashboard/conversion-report`);
  }

  calendarbuttonClick() {
    if (this.login_user_details.userinfo.user_type === "is_rep" && this.cookiedata.userinfo.calendar_id){
      this.router.navigateByUrl(`/rep-dashboard/calendar-management/event-list`);
    }else{
      this.matSnackBar.open("Create new availability, and get access to your calendar management.", "Ok", {
        duration: 7000
      });
      this.router.navigateByUrl(`/rep-dashboard/calendar-management/create-event`);
    }
  }

  presentaionPath() {
    if (this.login_user_details.userinfo.user_type === "is_admin")
      this.router.navigateByUrl(`/presentation-center`);
  }

  showbookingRoute(){
    if (this.login_user_details.userinfo.user_type === "is_admin")
    this.router.navigateByUrl(`/admin-dashboard/show_rep_wise_booking`);
  }

  gotoAssociate(){
    if (this.login_user_details.userinfo.user_type === "is_rep")
    this.router.navigateByUrl(`/rep-dashboard/associate`);
  }

  navigateToPath(path: string) {
    this.router.navigateByUrl(path)
  }

  choseProduct(owner:any){
    // if(owner === "beto"){
    this.navbuttonLoader = true
      this.apiservice.getHttpDataPost('product-management/get-products-details',{ has_betoparedes_access : this.login_user_details.userinfo.has_betoparedes_access !== 2 ? this.login_user_details.userinfo.has_betoparedes_access:undefined }).subscribe({
        next: (response: any) => {
          this.navbuttonLoader = false
            const dialogRef = this.dialog.open(FeatherComponentComponent,{
              panelClass: 'custom-modalbox',
              data:{
                responce:response.res,
                owner:owner
              }})
        }
      })
    // }
    // else if(owner === "nethun"){
      // let encode_url = `${environment.nethen_calender_redirect_link}`
      // let encoded = encodeURI(encode_url);
      // window.open(encoded) 
    // }
      
  
   

  }


  currentPath(): string {
    return this.router.url
    
  }
}
