import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private authService: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    console.log("next================>", next, state, this.router)

    if(state.url?.includes("/admin-dashboard/contract-management/request-contracts-list")) {

      let urlWithID = state.url
      sessionStorage.setItem("lastroute", urlWithID);
      
    }

  
    if(state.url?.includes("/admin-dashboard/leads/folder-view") || state.url?.includes("leads/folder-view") ) {

      let urlWithID = state.url
      sessionStorage.setItem("lastroute", urlWithID);
      
    }

    if (next.routeConfig?.path == 'admin-dashboard' && this.authService.isAdminAuthenticated()) return true


    if (next.routeConfig?.path === 'rep-dashboard' &&  (this.authService.isRepAuthenticated() || this.authService.isAdminAuthenticated() )
      //  && this.authService.checkTrainingCompletion() == '/rep-dashboard'
      ) {
      return true
    }

    // if (next.routeConfig?.path === 'rep-dashboard' && this.authService.isRepAuthenticated() && this.authService.checkTrainingCompletion() == '/trainings') {

    //   console.log("inside trainings");
      
    //   return true
    // }


    // if (next.routeConfig?.path == 'sign-contract' && (this.authService.isRepAuthenticatedcontract() || this.authService.isRepAuthenticatedcontract())) return true

    // if (next.routeConfig?.path == 'w9-form' && (this.authService.isRepAuthenticatedcontract() || this.authService.isRepAuthenticatedcontract())) return true

    // if (next.routeConfig?.path == 'customer-dashboard' && this.authService.isSocialAdvoAuthenticated()) return true

    if (next.routeConfig?.path == '') {
      const navigationRoute = this.authService.loggedInNavigation()
      console.log("navigationRoute==============>", navigationRoute)
      if (navigationRoute) this.router.navigateByUrl(navigationRoute);
      return true
    }
    
    this.router.navigateByUrl('/');
    return false;
  }
}
