import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { ApiservicesService } from 'src/app/services/apiservices.service';
@Component({
  selector: 'app-expired-lead-preview',
  templateUrl: './expired-lead-preview.component.html',
  styleUrls: ['./expired-lead-preview.component.css']
})
export class ExpiredLeadPreviewComponent {

  public fields: any;
  public formLoader: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private apiservice: ApiservicesService, public matSnackBar: MatSnackBar, public cookieService: CookieService, public router: Router, private dialogRef: MatDialogRef<ExpiredLeadPreviewComponent>,) { }

  ngOnInit() {
    console.log("this is lead preview data", this.data);
    this.fields = this.data?.value ? this.data.value : []

  }

  cancelButton() {
    this.closePreview();
    location.reload();
  }

  closePreview() {
    this.dialogRef.close()
  }

  getKeys(obj: any): Array<string> {
    console.log(Object.keys(obj));

    return Object.keys(obj);
  }

  converTimeReadAbleFormat(date: number) {
    return moment(date).format('MMMM Do YYYY, h:mm:ss a')
  }


  leadSubmit() {
    this.formLoader = true;
    this.closePreview();
    const login_user_details = this.cookieService.get('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : {};
    this.apiservice.getHttpDataPost('leads/lead-add', this.data.lead_add_payload).subscribe({
      next: (res: any) => {
        console.log("manageContactsData", this.data.lead_add_payload);


        this.matSnackBar.open(res.message, 'ok', { duration: 3000, });
        this.formLoader = true;
        if (login_user_details.userinfo.user_type == 'is_admin') {
          this.router.url.includes('/admin-dashboard/leads/leads-in-system/add-lead')
            ? this.router.navigateByUrl('/admin-dashboard/leads/leads-in-system')
            : this.router.navigateByUrl('/admin-dashboard/leads/leads-outside-system');
        } else if (login_user_details.userinfo.user_type == 'is_rep') {
          this.router.url.includes('/rep-dashboard/leads/leads-in-system/add-lead')
            ? this.router.navigateByUrl('/rep-dashboard/leads/leads-in-system')
            : this.router.navigateByUrl('/rep-dashboard/leads/leads-outside-system');
        }
      },
      error: (error: any) => {
        this.formLoader = false;
        this.matSnackBar.open(error.message, '', {
          duration: 5000,
        });

        if (login_user_details.userinfo.user_type == 'is_admin') {
          this.router.url.includes('/admin-dashboard/leads/leads-in-system/add-lead')
            ? this.router.navigateByUrl('/admin-dashboard/leads/leads-in-system')
            : this.router.navigateByUrl('/admin-dashboard/leads/leads-outside-system');
        } else if (login_user_details.userinfo.user_type == 'is_rep') {
          this.router.url.includes('/rep-dashboard/leads/leads-in-system/add-lead')
            ? this.router.navigateByUrl('/rep-dashboard/leads/leads-in-system')
            : this.router.navigateByUrl('/rep-dashboard/leads/leads-outside-system');
        }
      },
    });
  }


}
