<div class="dialoghead">
  <h1 class="mat-mdc-dialog-title">Success !!</h1>
  <mat-dialog-content>
    <p>
      Training successfully completed! <br />
     
    </p>
    <div class="mat-mdc-dialog-actions">
      <button mat-button (click)="goToCalender('no')">Book a Gameplan Call</button>
    </div>
    
  </mat-dialog-content>
</div>
