<div class="preview_modal">
    <h1 class="preview_header">Lead Information </h1>
      <div class="preview_contain">

        <ng-container *ngIf="haveLead"> 
          <p class="preview_data" *ngIf="leaddata?.name" >
            <span><strong> Lead Name : </strong> </span>
            <span class="preview_value">{{leaddata.name}}</span>
          </p>


          <p class="preview_data" *ngIf="leaddata?.email" >
            <span><strong> Lead Email : </strong> </span>
            <span class="preview_value">{{leaddata.email}}</span>
          </p>


          <p class="preview_data" *ngIf="leaddata?.phone_number">
            <span><strong> Lead Phone Number : </strong> </span>
            <span class="preview_value">{{leaddata.phone_number}}</span>
          </p>

          <p class="preview_data" *ngIf="leaddata?.city">
            <span><strong>Lead City : </strong> </span>
            <span class="preview_value">{{leaddata.city}}</span>
          </p>
        </ng-container>





        <ng-container *ngIf="haveRep">
          <p class="preview_data" *ngIf="repdata?.name">
            <span><strong>Rep Name : </strong> </span>
            <span class="preview_value">{{repdata.name}}</span>
          </p>

          <p class="preview_data" *ngIf="repdata?.email">
            <span><strong>Rep Email : </strong> </span>
            <span class="preview_value">{{repdata.email}}</span>
          </p>

          <p class="preview_data" *ngIf="repdata?.phone">
            <span><strong>Rep Phone Number : </strong> </span>
            <span class="preview_value">{{repdata.phone}}</span>
          </p>

          <p class="preview_data" *ngIf="repdata?.city">
            <span><strong>Rep city : </strong> </span>
            <span class="preview_value">{{repdata.city}}</span>
          </p>
        </ng-container>

        <ng-container *ngIf="!haveRep && !haveLead">
          <h1 class="coomingsoon">No Data Found</h1>
        </ng-container>


      </div>
  
    <span
      mat-button
      mat-dialog-close
      class="close-btn-modal material-icons"
      mat-raised-button
      matTooltip="Close"
      matTooltipPosition="below"
      (click)="closePreview()"
      >close</span
    >
  </div>
  