import { Component, Inject, Input, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Chart, ChartOptions, ChartType, registerables } from 'chart.js';

Chart.register(...registerables)

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnChanges {

  public clickcoun: any = {}

  public chartValue: any = {}
  public chart: any;

  @Input() data: any;


  constructor(private _ngZone: NgZone) { }

  ngOnChanges(changes: SimpleChanges) {
    console.log("changes=====>", changes);

    this.chartValue = changes['data'].currentValue
    console.log("changesssssssssss=====>", this.chartValue);

  }
  ngOnInit(): void {
    this._ngZone.runOutsideAngular(() => {
      this.createChart();
    })
  }

  createChart(): void {
    this.clickcoun = (this.chartValue?.clickcount - this.chartValue?.conversioncount)
    this.chart = new Chart("MyChart", {
      type: 'pie', //this denotes tha type of chart

      data: {// values on X-Axis
        labels: ['Click with no Conversion', 'Clicks with Conversion'],
        datasets: [{
          label: 'Count',
          data: [this.clickcoun , this.chartValue?.conversioncount],
          backgroundColor: [
            '#32a0de',
            '#0569ad',
          ],
          hoverOffset: 4
        }],

      },

      options: {
        aspectRatio: 2.5
      }

    });
  }


}
