import { Location } from '@angular/common';
import { Component } from '@angular/core';
import {FormBuilder,FormGroup,Validators,} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { convertToPDF } from 'src/app/admin-dashboard/contract-management/contract-list/contract-list.component';
import { ApiservicesService } from 'src/app/services/apiservices.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css'],
})
export class ContractComponent {
  public rep_name: any;
  public beto_name: any;
  public location: any;
  public city: any;
  public state: any;
  public zip: any;
  public signature: any;
  public date: any;
  public paramsId: any;
  public contractData: any;
  public update_id: any;
  public filedstatus: boolean = false;
  public loginForm: FormGroup;
  public validflag: any = {};
  public unic_value: string = '';
  public contractForm: FormGroup;
  public loader: boolean = false;
  public errors: any;
  public userData: any;
  public sign_flag: boolean = false;
  public today: any = moment(Date.now()).format('MM/DD/YYYY');
  public control: any;
  public dawnloadPdf: any = false;
  public storeValue: any = {};
  lead_contract_show: boolean = false;
  unamePattern = '^[a-z0-9_-]{2,5}$';
  isValidFormSubmitted = null;
  constructor(public activateRoute: ActivatedRoute,private apiService: ApiservicesService,private cookieService: CookieService,public router: Router,public matSnackBar: MatSnackBar,private contract: FormBuilder,public dialog: MatDialog,private Location: Location) {}

  public cookieData: any = this.cookieService.getAll()['login_user_details'] ? JSON.parse(this.cookieService.getAll()['login_user_details']) : {};
  // Function to disable all form controls in the FormGroup
  disableFormControls() {
    const formControls = Object.keys(this.loginForm.controls);
    for (const controlName of formControls) {
      this.loginForm.get(controlName)?.disable();
    }
  }



  ngOnInit() {

    const NewUserFrom = 1739181397000 // never change this mistakenly!!,This Done for a big reason
    console.log("NewUserFrom2:",this.cookieData.userinfo.created_at);
    if(this.cookieData.userinfo.created_at > NewUserFrom) {
      console.log("NewUserFrom:",this.cookieData.userinfo.created_at);
      this.lead_contract_show = true;
    }
    console.log("NewUserFrom3:",this.cookieData.userinfo.created_at);
    history.pushState(null, '', window.location.href);
    this.Location.subscribe(() => {
      history.pushState(null, '', window.location.href);
    });

    this.activateRoute.data.subscribe((response: any) => {
      if (response.data.status === 'success') {
        this.userData = response?.data?.results ? response.data.results[0] : [];
        this.loginForm = this.contract.group({
          date: [this.today, Validators.required],
          beto_name: ['Beto Paredes', Validators.required],
          rep_name: [this.userData?.name ? this.userData.name : '',Validators.required,],
          city: [this.userData?.city ? this.userData.city : '',Validators.required,],
          state: [this.userData?.city ? this.userData.state : '',Validators.required,],
          zip: [this.userData?.city ? this.userData.zip : '',Validators.required,],
          signature: [this.contractData?.signature ? this.contractData.signature : '',Validators.required,],
        });
      }
    });


    this.paramsId = this?.activateRoute?.snapshot?.params['_id'];

    if (this.activateRoute?.snapshot?.params &&this.activateRoute?.snapshot?.params['_id']) {
      this.activateRoute.data.subscribe((response: any) => {
        if (response.data && response.data.status == 'success') {
          this.contractData = response.data.results.results[0];
          this.update_id = response.data.results.results[0]['_id'];
          this.today = moment(this.contractData.date).format('MM/DD/YYYY');
          this.filedstatus = true;

          this.loginForm = this.contract.group({
            date: [this.contractData?.date ? this.contractData.date : '',Validators.required],
            rep_name: [this.contractData?.rep_name ? this.contractData.rep_name : '',Validators.required],
            city: [this.contractData?.rep_name ? this.contractData.city : '',Validators.required],
            state: [this.contractData?.email ? this.contractData.state : '',Validators.required],
            zip: [this.contractData?.phone ? this.contractData.zip : '',Validators.required],
            signature: [this.contractData?.signature ? this.contractData.signature : '',Validators.required],
          });
        }
      });
    }


    if (window.location.pathname.includes('/admin-dashboard/contract-sign') || this.activateRoute?.snapshot?.params['_id']) {
      this.sign_flag = true;
      this.someFunction(1,this.activateRoute?.snapshot?.params['_id'])
    }


  }


  async someFunction(redirect:number,_id:string) {
    try {
      const response = await this.createdDownloadDocument(_id);
      console.log('Document created successfully:', response);
      this.loader = false;
      if(redirect == 2){
        setTimeout(() => {
          window.location.href = `rep-dashboard/w9-form/`;
        }, 2000);
      }
    } catch (error) {
      console.error('Error creating document:', error);
    }
  }




  createdDownloadDocument(_id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.getHttpDataPost('leads/contract-pdf-upload', { "_id": _id }).subscribe({
        next: (response: any) => {
          if (response.status === 'success') {
            console.log("response", response);
            resolve(response);
          } else {
            reject(new Error("Failed to get successful response"));
          }
        },
        error: (error: any) => {
          this.matSnackBar.open('Something Went Wrong!', 'Ok', {
            duration: 3000,
          });
          reject(error);
        },
      });
    });
  }






  phoneFormatting = (data: any) => {
    if (data.split('').length <= 14) {
      var formatted = this.formatPhoneText(data);
    }
  };

  formatPhoneText(value: any) {
    value = value.trim().replaceAll('-', '');
    value = value.trim().replaceAll('(', '');
    value = value.trim().replaceAll(')', '');
    value = value.trim().replaceAll(' ', '');

    if (value.length > 3 && value.length <= 6)
      value = '(' + value.slice(0, 3) + ')' + ' ' + value.slice(3);
    else if (value.length > 6)
      value ='(' + value.slice(0, 3) +')' + ' ' + value.slice(3, 6) + '-' + value.slice(6);
    return value;
  }

  runing(event: any) {
    let value = String(event.target.value);
    let key = String(event.target.name);
    window.localStorage.setItem(key, value);
    if (event.target.name === 'rep_name') {
      this.errors = { ...this.errors, rep_name: '' };
    }

    if (event.target.name === 'city') {
      this.errors = { ...this.errors, city: '' };
    }
    if (event.target.name === 'state') {
      this.errors = { ...this.errors, state: '' };
    }
    if (event.target.name === 'zip') {
      this.errors = { ...this.errors, zip: '' };
    }
    if (event.target.name === 'signature') {
      this.errors = { ...this.errors, signature: '' };
    }
  }

  getName() {
    let val1 = window.localStorage.getItem('rep_name') ?? '';
    let val2 = window.localStorage.getItem('city') ?? '';
    let val3 = window.localStorage.getItem('state') ?? '';
    let val4 = window.localStorage.getItem('zip') ?? '';
    let val5 = window.localStorage.getItem('signature') ?? '';

    this.storeValue = {rep_name: val1,city: val2,state: val3,zip: val4,signature: val5,};
  }







  contractSubmit() {

    if (this.loginForm.status === 'INVALID') {
      this.matSnackBar.open('Signature is required', 'ok', {
        duration: 3000,
      });
    }


    if (this.loginForm?.['controls']?.['rep_name']?.['errors']?.['required']) {
      this.errors = { ...this.errors, rep_name: 'Rep name in Required' };
    }

    if (this.loginForm?.['controls']?.['state']?.['errors']?.['required']) {
      this.errors = { ...this.errors, name: 'State in Required' };
    }
    if (this.loginForm?.['controls']?.['city']?.['errors']?.['required']) {
      this.errors = { ...this.errors, email: 'City in Required' };
    }
    if (this.loginForm?.['controls']?.['zip']?.['errors']?.['required']) {
      this.errors = { ...this.errors, phone: 'Zip in Required' };
    }

    if (this.loginForm?.['controls']?.['signature']?.['errors']?.['required']) {
      this.errors = { ...this.errors, signature: 'Signature is Required' };
    } else {
      const login_user_details = this.cookieService.get('login_user_details')? JSON.parse(this.cookieService.get('login_user_details')): {};
      const keyaree = Object.keys(this.loginForm.value);
      this.loader = true;
      this.apiService.getHttpDataPost('contract/contract-add', {
          is_rep_contract_signed: 1,
          has_betoparedes_access: 1,
          user_id: login_user_details.userinfo._id,
          ...this.loginForm.value,
        })
        .subscribe({
          next: (response: any) => {
            if (response.status === 'success') {
              let namearr = this.loginForm.value.rep_name.split(' ');
              let oldcookie = JSON.parse(this.cookieService.get('login_user_details'));
              let newcookie = {
                lastLoginTime: oldcookie.lastLoginTime,
                token: oldcookie.token,
                token_expiry: oldcookie.token_expiry,
                expires_time:oldcookie.expires_time,
                userinfo: {
                  ...oldcookie.userinfo,
                  has_betoparedes_access: 1,
                  is_rep_contract_signed: 1,
                  name: this.loginForm.value.rep_name,
                  city: this.loginForm.value.city,
                  state: this.loginForm.value.state,
                  zip: this.loginForm.value.zip,
                  firstname: namearr[0],
                  lastname: namearr[1],
                },
              };
              // let val = this.createdDownlodDocument(oldcookie.userinfo._id)
              // const response = await this.createdDownloadDocument(this.activateRoute?.snapshot?.params['_id'])
              // console.log("createdDownlodDocument",val);

              this.someFunction(2,oldcookie.userinfo._id)
              
              
              this.cookieService.set('login_user_details',JSON.stringify(newcookie),{ path: '/' ,expires: new Date(newcookie.expires_time)});
              this.matSnackBar.open('Contract Signed Successfully!', 'Ok', {duration: 3000,});

            }
          },
          error: (error: any) => {
            this.loader = false;
            this.matSnackBar.open('Something Went Wrong!', 'Ok', {
              duration: 3000,
            });
          },
        });
    }
  }






  pdfConverter() {
    this.dawnloadPdf = true;
    let pdfId: any = document.getElementById('contract_form')?.innerHTML;
    this.dialog.open(convertToPDF, {
      panelClass: ['custom-modalbox', 'converttopdf-modalbox'],
      data: { pdfhtml: pdfId, key: 'contract' },
    });
  }






  clickPrint() {
    window.open(`${environment.api_url}leads/download-contract-pdf?id=${this.activateRoute?.snapshot?.params['_id']}&contract_type=associate-contract`, '_blank');
  }








  emailsend(){

    this.loader = true;
    this.apiService.getHttpDataPost('leads/send-contract-by-email', {"_id":this.activateRoute?.snapshot?.params['_id'],"contract_type":"associate-contract" }).subscribe({
      next: (response: any) => {
        if (response.status === 'success') {
          this.matSnackBar.open('Document sent to your email.', 'Ok', {
            duration: 3000,
          })
          this.loader = false;


        }
      },
      error: (error: any) => {
        this.matSnackBar.open('Something Went Wrong!', 'Ok', {
          duration: 3000,
        });
      },
    });
  }






}
