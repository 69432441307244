import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { PreviewComponentsComponent } from 'src/app/Common-components/preview-components/preview-components.component';
import { PreviewComponent } from 'src/app/Common-components/preview/preview.component';
import { ApiservicesService } from 'src/app/services/apiservices.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login-modal',
  templateUrl: './lead-events-list.component.html',
  styleUrls: ['./lead-events-list.component.css'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, SharedModule, CommonModule],
})
export class LeadEventsListComponent {
  public tabledatatalist: any = [];






  constructor(private apiservice: ApiservicesService, private dialogRef: MatDialogRef<LeadEventsListComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, public matSnackBar: MatSnackBar, public cookieService: CookieService, public router: Router) {
    this.tabledatatalist = data.loged_inData

  }

  ngOnInit() {
    console.log("this is lead preview data", this.data);
    // this.previewFile()
  }
  previewFile(dataItem: any) {
    console.log("fileData-------->>", dataItem);
    console.log("name-------->>", dataItem.title);

    this.dialog.open(PreviewComponentsComponent, {
      data: {
        fileData: dataItem,
        name: dataItem.title
      },
      panelClass: ['custom-modalbox', 'file-modalbox'],
    });
  }


  closePreview() {
    this.dialogRef.close()
  }

  getKeys(obj: any): Array<string> {
    console.log(Object.keys(obj));

    return Object.keys(obj);
  }
}
