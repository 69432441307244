import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { NavigationEnd, NavigationError, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public baseUrl: any = environment.api_url;
  title = 'betoparedes-v3';
  apiUrl = this.baseUrl + 'user/last-rep-user-id';
  apiData: any;
  constructor(public router: Router, private http: HttpClient) {
    // console.log("environment========>", environment)
  }
  color: ThemePalette = 'accent';
  mode: ProgressBarMode = 'indeterminate';
  public loader: boolean = false;

  ngOnInit() {
    // Loader Config
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart:
          this.loader = true;
          break;
        case event instanceof NavigationEnd:
          this.loader = false;
          break;
        case event instanceof NavigationError:
          this.loader = false;
          break;
      }
    });

    // Call API initially
    this.callApi();

    // Set up interval to call API every 15 seconds
    setInterval(() => {
      this.callApi();
      this.trackDiscoveryCallRunApi();
    }, 20000);
  }
  callApi() {
    this.http.get(this.apiUrl).subscribe(
      (response) => {
        this.apiData = response;
        // Do something with the API response (e.g., update a variable, display it in the template)
      },
      (error) => {
        console.error('Error fetching API data:', error);
      }
    );
  }
  trackDiscoveryCallRunApi() {
    this.http.post<any>('https://i526y91jwg.execute-api.us-east-2.amazonaws.com/dev/api/find-parent-leadId', {})
      .subscribe({
        next: (response) => {
          console.log("Discovery response:", response);

          if (!response?.data) {
            console.error('Invalid response from find-parent-leadId API:', response);
            return;
          }

          this.http.post<any>(`${this.baseUrl}leads/track-discovery-call-data`, response.data)
            .subscribe({
              next: (newResponse) => {
                console.log("Discovery response new:", newResponse);

                if (!newResponse?.results) {
                  console.error('Invalid response from track-discovery-call-data API:', newResponse);
                  return;
                }

                this.http.post<any>('https://i526y91jwg.execute-api.us-east-2.amazonaws.com/dev/api/update-sync-flag', { data: newResponse.results })
                  .subscribe({
                    next: (finalResponse) => {
                      console.log("Final discovery response:", finalResponse);
                    },
                    error: (error) => {
                      console.error('Error in update-sync-flag API:', error);
                    }
                  });
              },
              error: (error) => {
                console.error('Error in track-discovery-call-data API:', error);
              }
            });
        },
        error: (error) => {
          console.error('Error in find-parent-leadId API:', error);
        }
      });
  }


}


