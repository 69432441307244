import { Injectable } from '@angular/core';
import { ApiservicesService } from './apiservices.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ResolveService {

  constructor(public apiService: ApiservicesService, private cookieService: CookieService, private router: Router) { }

  public userCookie: any = JSON.parse(this.cookieService.get('login_user_details'))

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,

  ): Observable<any> | Promise<any> | any {
    console.log("this is route data", route);

    let reqBody = route?.data?.['requestcondition'];
    console.log("route==========>", route.routeConfig, route.params['_id'], state.url, this.userCookie);

    if (state.url.includes('/admin-dashboard/reps/edit-reps')) {
      route.data['requestcondition']['_id'] = route.params['id']
    }

    if (state.url.includes('/admin-dashboard/leads/edit-lead') || state.url.includes('/rep-dashboard/leads/edit-lead')) {
      route.data['requestcondition']['_id'] = route.params['id']
    }
    if (state.url.includes('/admin-dashboard/leads/folder-view') || state.url.includes('/rep-dashboard/leads/folder-view')) {
      route.data['requestcondition']['_id'] = route.params['id']
    }
    if (state.url.includes('/admin-dashboard/files/files-category-edit')) {
      route.data['requestcondition']['_id'] = route.params['id']
    }
    if (state.url.includes('/admin-dashboard/opportunity/product-category-edit')) {
      route.data['requestcondition']['_id'] = route.params['id']
    }
    if (state.url.includes('/admin-dashboard/files/files-managment-edit')) {
      route.data['requestcondition']['_id'] = route.params['id']
    }
    if (state.url.includes('admin-dashboard/video/video-category-edit')) {
      route.data['requestcondition']['_id'] = route.params['id']
    }
    if (state.url.includes('admin-dashboard/video/video-managment-edit')) {
      route.data['requestcondition']['_id'] = route.params['id']
    }
    if (state.url.includes('admin-dashboard/opportunity/product-management-edit')) {
      route.data['requestcondition']['_id'] = route.params['_id']
    }

    if (state.url.includes('admin-dashboard/miscellaneous/banner-edit')) {
      route.data['requestcondition']['_id'] = route.params['id']
    }

    if (state.url.includes('admin-dashboard/user/edit-user')) {
      route.data['requestcondition']['_id'] = route.params['_id']
    }

    if (state.url.includes('admin-dashboard/disposition/edit-disposition')) {
      reqBody['_id'] = route.params['id']
    }

    if (state.url.includes('edit-follwups')) {
      reqBody['id'] = route.params['id']
    }
    if (state.url.includes('edit-flowup-list')) {
      reqBody['id'] = route.params['id']
    }

    if(state.url.includes('associate')){
      const userCookie = JSON.parse(this.cookieService.get('login_user_details'))
      route.data['requestcondition']['searchcondition']['affiliate_id'] = userCookie?.userinfo._id
    }


    if (route.routeConfig?.path == 'account-info/:_id') {
      route.data['requestcondition']['_id'] = route.params['_id']
    }
    if (state.url == ('/rep-dashboard')) {
      const userCookie = JSON.parse(this.cookieService.get('login_user_details'))

      if(userCookie.userinfo.is_rep_contract_signed == 0){
        this.router.navigateByUrl(`/rep-dashboard/sign-contract`)
      }else if(userCookie.userinfo.is_w9_form_signed == 0){
        this.router.navigateByUrl(`/rep-dashboard/w9-form`);
      }else if(userCookie.userinfo?.is_affiliate != null && userCookie.userinfo?.is_affiliate == 0){
        this.router.navigateByUrl(`/rep-dashboard/affiliate-sign-contract/`+userCookie.userinfo?._id);
      }
      else{
        route.data['requestcondition']['user_id'] = userCookie?.userinfo._id
        route.data['requestcondition']['has_betoparedes_access'] = userCookie?.userinfo?.has_betoparedes_access ? userCookie.userinfo.has_betoparedes_access : 0
      }
    }
    if (state.url == ('/trainings') || state.url == ('/training-video')) {
      const userCookie = JSON.parse(this.cookieService.get('login_user_details'))

      route.data['requestcondition']['searchcondition']['rep_id'] = userCookie?.userinfo._id
      
    }
    console.log(state.url,"state.url");

    if (state.url == ('/rep-dashboard/leads/leads-in-system') ||
      state.url == ('/rep-dashboard/leads/leads-outside-system') ||
      state.url.includes('leads-in-medical-lab') ||
      state.url == ('/admin-dashboard/leads/leads-outside-system') ||
      state.url == ('/admin-dashboard/leads/leads-in-system') ||
      state.url.includes('/admin-dashboard/leads/leads-in-system/list')) {
        console.log("------ in http://localhost:59657/rep-dashboard/leads/leads-in-medical-lab")

      const userCookie = JSON.parse(this.cookieService.get('login_user_details'))
      // console.log("userCookie",userCookie.userinfo);
      if (userCookie.userinfo.user_type === 'is_rep' || route.params['id']) {

        let repID
        if (route.params['id']) {
          repID = route.params['id']
        } else if (userCookie.userinfo._id) {
          repID = userCookie.userinfo._id
        }
        if(!state.url.includes('leads-in-medical-lab'))
          route.data['requestcondition']['searchcondition']['rep_id'] = repID

        if( state.url.includes('leads-in-medical-lab')) 
          route.data['requestcondition']['searchcondition']['type']={"$in":["Medical Company","Laboratory"]}
      }
    }

    if (route.routeConfig?.path == 'rep-lead/:id') {
      route.data['requestcondition']['searchcondition']['rep_id'] = route.params['id']
    }

    // << ----------- Request Contract List ------------- >>

    if (route.routeConfig?.path == 'request-contracts-list/:id') {
      route.data['requestcondition']['searchcondition']['contract_id'] = route.params['id']
    }

    // << ----------- Request Contract List ------------- >>

    if(state.url == '/rep-dashboard/contract-status-list') {
      route.data['requestcondition']['searchcondition']['user_id'] = this.userCookie.userinfo._id
    }

    if (route.routeConfig?.path == 'contract-sign/:_id') {
      route.data['requestcondition']['_id'] = route.params['_id']
      console.log("paramsssss",route.params['_id']);
      
    }
    if (route.routeConfig?.path == 'w9-form/:_id') {
      route.data['requestcondition']['_id'] = route.params['_id']
      console.log("paramsssss",route.params['_id']);
      
    }


    if (route.routeConfig?.path == 'user-edit/:_id') {
      route.data['requestcondition']['_id'] = route.params['_id']
    }
    if (route.routeConfig?.path == 'edit-slug/:id') {
      route.data['requestcondition']['_id'] = route.params['id']
    }
    if (route.routeConfig?.path == 'email-template-edit/:_id') {
      route.data['requestcondition']['_id'] = route.params['_id']
    }
    if (state.url.includes('rep-dashboard/sign-contract')) {
      const userCookie = JSON.parse(this.cookieService.get('login_user_details'))
      route.data['requestcondition']['_id'] = userCookie.userinfo._id ? userCookie.userinfo._id: ""
    }
    if (state.url.includes('rep-dashboard/affiliate-sign-contract')) {
      route.data['requestcondition']['_id'] = route.params['id']

    }
    if (state.url.includes('rep-dashboard/training-video')) {
      const userCookie = JSON.parse(this.cookieService.get('login_user_details'))
      route.data['requestcondition']['is_betoparedes'] = userCookie.userinfo.has_betoparedes_access ? userCookie.userinfo.has_betoparedes_access: ""
    }
    if (state.url.includes('rep-dashboard/asset-menu')) {
      const userCookie = JSON.parse(this.cookieService.get('login_user_details'))
      route.data['requestcondition']['is_betoparedes'] = userCookie.userinfo.has_betoparedes_access ? userCookie.userinfo.has_betoparedes_access: ""
    }


    ///////////////////////////calendar management////////////////////////


    if (state.url.includes('/calendar-management/event-list')) {
      const userCookie = JSON.parse(this.cookieService.get('login_user_details'))
      route.data['requestcondition']['searchcondition']['userid'] = userCookie.userinfo.calendar_id ? userCookie.userinfo.calendar_id: ""
    }
    if (state.url.includes('/calendar-management/editEvent')) {
      route.data['requestcondition']['searchcondition']['_id'] = route.params['_id']
    }
    if (state.url.includes('/calendar-management/booked-slots')) {
      const userCookie = JSON.parse(this.cookieService.get('login_user_details'))
      route.data['requestcondition']['searchcondition']['userid'] = userCookie.userinfo.calendar_id ? userCookie.userinfo.calendar_id: ""
    }

    ///////////////////////////calendar management end////////////////////////


    // <<<-------------------Invoice Edit-------------------------------------->>>
    if (route.routeConfig?.path == 'edit-invoice/:id') {
      route.data['requestcondition']['_id'] = route.params['id']
    }
    // <<<------------------------Invoice Edit--------------------------------->>>

    // << ------------ Contract Edit By Sanket --------------- >>

    if (route.routeConfig?.path == 'edit-contract/:id') {
      route.data['requestcondition']['_id'] = route.params['id']
    }

    // << ------------ Contract Edit By Sanket --------------- >>

    // << ------------ Sent Contract Edit By Sanket --------------- >>

    if (route.routeConfig?.path == 'edit-sent-contract/:id') {
      route.data['requestcondition']['_id'] = route.params['id']
    }

    // << ------------ Sent Contract Edit By Sanket --------------- >>

    if (route.routeConfig?.path == 'user-edit/:_id') {
      route.data['requestcondition']['_id'] = route.params['_id']
    }

    if (state.url == ('/rep-dashboard/clickreport')) {
      const userCookie = JSON.parse(this.cookieService.get('login_user_details'))
      // console.log("userCookie",userCookie.userinfo);
      if (userCookie.userinfo.user_type === 'is_rep') {
        let startval = moment().startOf('month').valueOf()
        let endval = moment().endOf('month').valueOf()
        route.data['requestcondition']['searchcondition']['user_id'] = userCookie.userinfo._id ? userCookie.userinfo._id : ''
        route.data['requestcondition']['searchcondition']['created_on'] = { "$gte": startval, "$lte": endval } ? { "$gte": startval, "$lte": endval } : undefined

      }
    }
    if (state.url == ('/rep-dashboard/conversion-report')) {
      const userCookie = JSON.parse(this.cookieService.get('login_user_details'))
      // console.log("userCookie",userCookie.userinfo);
      if (userCookie.userinfo.user_type === 'is_rep') {
        let startval = moment().startOf('month').valueOf()
        let endval = moment().endOf('month').valueOf()
        route.data['requestcondition']['searchcondition']['user_id'] = userCookie.userinfo._id ? userCookie.userinfo._id : ''
        route.data['requestcondition']['searchcondition']['created_on'] = { "$gte": startval, "$lte": endval } ? { "$gte": startval, "$lte": endval } : undefined
      }
    }

    if (state.url == ('/admin-dashboard/report/conversion-report')) {
      const userCookie = JSON.parse(this.cookieService.get('login_user_details'))
      // console.log("userCookie",userCookie.userinfo);
      if (userCookie.userinfo.user_type === 'is_admin') {
        let startval = moment().startOf('month').valueOf()
        let endval = moment().endOf('month').valueOf()
        // route.data['requestcondition']['searchcondition']['_id'] = userCookie.userinfo._id ? userCookie.userinfo._id : ''
        route.data['requestcondition']['searchcondition']['created_at'] = { "$gte": startval, "$lte": endval } ? { "$gte": startval, "$lte": endval } : undefined

      }
    }
    if (state.url == ('/admin-dashboard/report/click-report')) {
      const userCookie = JSON.parse(this.cookieService.get('login_user_details'))
      // console.log("userCookie",userCookie.userinfo);
      if (userCookie.userinfo.user_type === 'is_admin') {
        let startval = moment().startOf('month').valueOf()
        let endval = moment().endOf('month').valueOf()
        // route.data['requestcondition']['searchcondition']['_id'] = userCookie.userinfo._id ? userCookie.userinfo._id : ''
        route.data['requestcondition']['searchcondition']['created_at'] = { "$gte": startval, "$lte": endval } ? { "$gte": startval, "$lte": endval } : undefined

      }
    }
    if (state.url.includes('/admin-dashboard/report/click-report/details-click-report') || state.url.includes('/admin-dashboard/report/conversion-report/details-conversion-report')) {
      let startval = moment().startOf('month').valueOf()
      let endval = moment().endOf('month').valueOf()
      route.data['requestcondition']['searchcondition']['user_id'] = route.params['_id']
      route.data['requestcondition']['searchcondition']['created_on'] = { "$gte": startval, "$lte": endval } ? { "$gte": startval, "$lte": endval } : undefined

    }



    return new Promise((resolve, reject) => {


      if(route.data['endpoint'] === "googleapi/get-all-beto-events"){
        this.apiService.getHttpGooglePost(route?.data?.['endpoint'], reqBody).subscribe({
          next: (res: any) => {
            return resolve(res)
          },
          error: (error: any) => {
            return reject(error)
          }
        })
        return
      }

      
      if (reqBody) {
        if (route?.data?.['crossdomain']) {
          this.apiService.getHttpDataPostWithoutBaseUrl(route?.data?.['endpoint'], reqBody).subscribe({
            next: (res: any) => {
              return resolve(res)
            },
            error: (error: any) => {
              return reject(error)
            }
          });
        } else {
          this.apiService
            .getHttpDataPost(route?.data?.['endpoint'], reqBody)
            .subscribe({
              next: (res: any) => {
                return resolve(res)
              },
              error: (error: any) => {
                return reject(error)
              }
            });
        }
      } else {
        this.apiService
          .getHttpData(route?.data?.['endpoint'])
          .subscribe({
            next: (res: any) => {
              return resolve(res)
            },
            error: (error: any) => {
              return reject(error)
            }
          });
      }
    });
  }
}
