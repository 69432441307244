import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { ApiservicesService } from '../services/apiservices.service';
import { FormControl } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-feather-component',
  templateUrl: './feather-component.component.html',
  styleUrls: ['./feather-component.component.css'],
})
export class FeatherComponentComponent {
  public ChoseProduct: any = {};
  public productData: any;
  public owner: any;
  public lead_obj_for_folderview: any = null;
  public chekValue: any = null;
  public loged_user: any = null;
  public open_auto_conplete: boolean = false;
  public productData_loader: boolean = false;
  public autocompleteData: any = [];
  public selected_value: any = {};
  public desabel_fields: boolean = false;

  selectedProduct: string;
  selectedItem: string;

  autoconplete_input_val = new Subject<string>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    public cookieService: CookieService,
    private apiservice: ApiservicesService,
    private _snackBar: MatSnackBar
  ) {
    this.productData = data.responce;

    this.owner = data.owner;

    if (data.lead_obj_for_folderview) {
      this.lead_obj_for_folderview = data.lead_obj_for_folderview;
    }

    this.loged_user = this.cookieService.get('login_user_details')
      ? JSON.parse(this.cookieService.get('login_user_details'))
      : null;
  }

  identify(index: any, item: any) {
    return item._id;
  }

  ngOnInit() {
    if (this.lead_obj_for_folderview) {
      this.selected_value = {
        _id: this.lead_obj_for_folderview._id,
        val: this.lead_obj_for_folderview.name,
        key: this.lead_obj_for_folderview._id,
        email: this.lead_obj_for_folderview.email,
      };
      this.selectedProduct = this.lead_obj_for_folderview.source_id_obj;
      this.chekValue = this.lead_obj_for_folderview.source_id_obj;
      this.fetchAutocompleteData('id', this.chekValue);
      this.selectedItem = this.lead_obj_for_folderview.name;
      this.desabel_fields = true;
    }

    this.autoconplete_input_val
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((value) => {
        console.log(value);
        this.fetchAutocompleteData('name', value);
      });
  }

  onSelectChange(event: any = '', name: any = '') {
    this.chekValue = event.value ? event.value : this.chekValue;
    this.fetchAutocompleteData('id', this.chekValue);
  }

  fetchAutocompleteData(field: any, value: any) {
    this.productData_loader = true;

    let obj: any = {
      searchcondition: {
        rep_id: this.loged_user.userinfo._id,
      },
    };

    if (field === 'id') {
      obj.searchcondition['id'] = this.chekValue;
      obj.searchcondition['name'] = '';
    } else if (field === 'name') {
      obj.searchcondition['name'] = value;
      obj.searchcondition['id'] = this.chekValue;
    }

    this.apiservice
      .getHttpDataPost('leads/lead-name-autocomplete', obj)
      .subscribe({
        next: (responce: any) => {
          if (responce.status === 'success') {
            this.productData_loader = false;
            this.open_auto_conplete = true;
            this.autocompleteData = responce.res;
          }
        },
      });
  }

  onItemChange(selectedItem: string) {
    this.selected_value = this.getdataFromAutoComlete(selectedItem);
  }

  getdataFromAutoComlete(val: any) {
    for (let i = 0; i < this.autocompleteData.length; i++) {
      let item = this.autocompleteData[i];
      if (item.val === val) {
        return item;
      }
    }
  }

  buttonClickUrlHit() {
    if (Object.keys(this.selected_value).length > 0) {
      // console.log('selected_value', this.selected_value);
      // return;

      if (this.owner == 'beto') {
        let encode_url = `${environment.beto_calender_redirect_link}?product_id=${this.chekValue}&name=${this.selected_value.val}&email=${this.selected_value.email}&leadId=${this.selected_value._id}`;
        let encoded = encodeURI(encode_url);
        window.open(encoded);
      } else if (this.owner == 'nethun') {
        let encode_url = `${environment.nethen_calender_redirect_link}?product_id=${this.chekValue}&name=${this.selected_value.val}&email=${this.selected_value.email}&leadId=${this.selected_value._id}`;
        let encoded = encodeURI(encode_url);
        window.open(encoded);
      }
    } else {
      this._snackBar.open('Select Product and Lead First');
    }
  }

  handleKeyUp(event: any) {
    this.selected_value = {};
    this.autoconplete_input_val.next(event.target.value);
  }
}
