<!-- ////////////////////////////////////////////////////css_start////////////////////////////////////////////////////////////// -->

<!-- ////////////////////////////////////////////////////css_end////////////////////////////////////////////////////////////// -->

<!-- ////////////////////////////////////////////////////html_start////////////////////////////////////////////////////////////// -->
<app-header></app-header>
<div class="main_wrp" *ngIf="!sign_flag">
  <div class="sub_wrp">

    <div class="img_wrp">
      <img src="https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/home/Beto_Logo.png" />
    </div>

    <div class="text_wrp">

      <form class="form_wrp" name="loginForm" [formGroup]="loginForm">

        <h1>Contract Sales Representative Agreement nnn</h1>

        <p>This agreement ("Agreement") is made between BetoParedes,LLC ("Company"), representing the Clinical Speed Underpayment Audit Programs with the  Beto
          Paredes Family of Companies , and

          <strong class="salesperson_name">
            <mat-form-field class="textfirld_wrp">
              <input matInput name="rep_name" placeholder="Associate Director" formControlName="rep_name" required
                class="textfirld_subwrp" (input)="runing($event)" [value]="loginForm.value.rep_name" />
            </mat-form-field>
            <mat-error *ngIf="errors?.rep_name">Rep name is required</mat-error>
          </strong>
        </p>

        <h3>1. ENGAGEMENT OF SALES REPRESENTATIVE</h3>
        <p>1.1. The Company hereby engages the Associate Director to represent and promote the sale of products and services offered by the Company by the terms and conditions outlined in this Agreement.</p>
        
        <h3>2. COMPLIANCE WITH REGULATIONS AND COMPANY POLICIES</h3>
        <p>2.1. The Associate Director agrees to strictly comply with all applicable federal, state, and local laws, regulations, and industry standards, as well as any policies, guidelines, and training the Company provides regarding promoting and selling the Brands' products and services.</p>
        
        <h3>3. SALES TRAINING AND BRAND REPRESENTATION</h3>
        <p>3.1. The Associate Director agrees to participate in and complete all mandatory training sessions the Company provides to ensure proper representation of the Brands. The Associate Director shall accurately and faithfully represent the brands' products and services in all communications, marketing materials, and sales efforts.</p>
        
        <h3>4. CONFIDENTIALITY AND INTELLECTUAL PROPERTY</h3>
        <p>4.1. The Associate Director acknowledges that they may have access to confidential and proprietary information regarding the Brands. The Sales Representative agrees to maintain such information's confidentiality and not disclose it to any third party. All intellectual property, including but not limited to trademarks, logos, and promotional materials, remains the Company's exclusive property.</p>
        
        <h3>5. NON-DISCLOSURE AND NON-CIRCUMVENTION</h3>
        <p>5.1. During the term of this Agreement, the Associate Director agrees not to engage in any activity or represent any other business that competes directly with the Clinical Speed Auditing Software products and services offered by the Beto Paredes Family of Companies. The Associate Director must disclose any potential conflicts of interest to the Company.</p>
        <p>5.2. The Associate Director agrees not to disclose any details of this Agreement, the details of protected software IP, or protected marketplace models to any competing entity or third party without the explicit permission of the Company.</p>
        <p>5.3. The Associate Director agrees that all salespeople, leads, and clients referred to the Company become clients of the Company, and the Associate Director shall not refer other 835 forensic auditing services to these clients for services that the Company provides without exclusive permission.</p>
        
        <h3>6. TERM OF AGREEMENT</h3>
        <p>6.1. Either party may terminate this Agreement upon 60 days written notice or immediately upon breach of any terms of this Agreement by the Associate Director.</p>
        
        <h3>7. GOVERNING LAW AND FORUM</h3>
        <p>7.1. This Agreement shall be governed by and construed by the laws of the State of Idaho and the United States of America. Any arbitration or litigation between the Parties shall be conducted exclusively in Idaho.</p>
        
        <h3>8. REFERRALS AND COMMISSIONS</h3>
        <p>8.1. The Parties agree that the Associate Director may introduce potential clients to the Company in return for a commission on closed contracts by the client pursuant to the terms of this Agreement signed by the Parties.</p>
        <p>8.2. Referrals must first be submitted to the Company for review to receive validation of the lead, and then a potential client meeting is scheduled with the Company by the Associate Director.</p>
        <p>8.3. Commission will be paid only after the Company receives payment for the sale from the client. The company will not be held liable to pay the Commission on any funds that are uncollected or refunded.</p>
        <p>8.4. Commission Structure</p>
        <p>Personal Direct Sales: The Associate Director will receive a 15% commission on underpayments clients that are brought to a discovery call, attended by the Associate Director, of which are closed accounts. This is based on the agreed to percentage set with the client and actual monies received. Additionally, the Associate Director will receive a 3% commission on any upsells that are presented and sold directly on any other platforms offered by the Company that may be sold to the Client, or through the client to labs and/or providers. This is paid only to the Associate Director that is the closing party. The Company will inform the Associate Director of any additional sales once contracts are sold.</p>
        <p>8.5. Commissions will be paid monthly, net seven, meaning they will be paid on or before the 7th day after the end of the month in which the Company received the funds.</p>
        <p>8.6. The Associate Director will be co-responsible for requesting contracts, verifying receipt of contracts, and aiding in the receipt of signatures for all contracts. The associate is also responsible for assisting in the scheduling of initial kickoff meetings with onboarding staff.</p>
        <p>8.7. The Associate Director will be paid commissions for all referrals made during the term of this Agreement.</p>
        <p>8.8. Direct Closing Bonuses:</p>
        <p>Bonus commissions:</p>
        <ul class="sale_commission">
          <li class="sale_commission_prodict">
            <h5 class="sale_commission_prodict_header">An additional 2% for Clinical Speed Contracts.</h5>
          </li>
        </ul>
        <p>Requirements: If the following requirements are met, you can apply to close contracts and send them for signature without the assistance of Senior sales staff by first setting discovery and then requesting a contract.</p>
        <ul class="sale_commission">
          <li class="sale_commission_prodict">
            <h5 class="sale_commission_prodict_header">Participate in closing one (1) deal for the Clinical Speed Underpayment programs.</h5>
          </li>
          <li class="sale_commission_prodict">
            <h5 class="sale_commission_prodict_header">Take the contract closers exam. Once accomplished you will get access to send contracts directly. You are always welcome to still go through our Discovery team, but in those cases the bonuses are void.</h5>
          </li>
        </ul>
        <p>8.9. Associate Director is given permission to introduce additional Associate Directors to the program by sharing their sign up link available in the back office. If a sales rep under the Associate director makes a sale of the Clinical Speed programs, the Associate Director will receive 2.5% following the same policy of the initial commission of that sale.  In addition if the recruited Associate director underneath them also signs up an Associate Director on a third tier the Associate Director  will receive 1% following the same policy of the initial commission of that sale.</p>
        
        <h3>9. COMMISSION SCHEDULE</h3>
        <p>9.1. Commission to Associate Director for closed contracts will be paid out based on Company’s current fee schedule during the establishment of each Referral Contract.</p>
        <p>9.2. Any commission and residual rates in effect at the time of an Initial and/or successive contract will remain in place unaltered by any later changes to this schedule. Changes will only affect new Referral contracts closed after the change date.</p>
        <p>9.3. Associate Director will be given full disclosure of the payment schedule paid by Referral Contract.</p>
        <p>9.4. Commissions are based on current pricing and may be adjusted as pricing changes.</p>
        
        <h3>10. EXPENSES</h3>
        <p>10.1. Associate Director is responsible for covering all costs related to travel, managing their own sales organization, and training them. Expenses incurred in the sales process belong to the sales people directly.</p>
        
        <h3>11. INDEMNIFICATION</h3>
        <p>11.1. Associate Director shall not be liable for any claim or demand made against Company by any third party. Company shall indemnify Associate Director against all claims, liabilities, and costs, including reasonable attorney fees, for defending any third-party claim arising from the use of the software provided under this Agreement.</p>
        
        <h3>12. ENTIRE AGREEMENT</h3>
        <p>12.1. This Agreement contains the entire understanding of the Parties and supersedes any prior agreements. Any modifications must be in writing and signed by both Parties.</p>
        
        <h3>13. INDEPENDENT CONTRACTOR RELATIONSHIP</h3>
        <p>13.1. The Parties acknowledge that the Associate Director will perform its obligations hereunder as an independent contractor and shall not be an employee of Company.</p>
        
        <h3>14. AGREEMENT EXECUTION</h3>
        <p>14.1. The Parties hereto agree to all aspects of this Agreement and that facsimile/email signatures shall be as effective as if originals.</p>
        
        <h3>15. ASSOCIATE DIRECTOR RESPONSIBILITIES</h3>
        <p>15.1. Complete training before entering in leads on the Clinical Speed product.</p>
        <p>15.2. Enter in leads for validation to move forward in our back office lead entry system.</p>
        <p>15.3. Once validated you will be emailed within 48 hours business days.</p>
        <p>15.4. Then you set a zoom call in our calendar back office for your prospect to meet with our discovery team.</p>
        <p>15.5. You are required to be on these calls to make introductions.</p>
        <p>15.6. Once the call is completed we will work to keep the chain of custody tight and set the next meeting time. If things are not able to stay tight with your potential client we will be turning it back over to you to get back on our schedules.</p>
        
        <h3>16. LEAD MANAGEMENT POLICY</h3>
        <div class="policy-section">
          <p>As an Associate Director, you are required to adhere to the following guidelines when submitting leads into the system:</p>
          <p>16.1. Lead Qualification</p>
          <ul class="sale_commission">
            <li class="sale_commission_prodict">
              <h5 class="sale_commission_prodict_header">You have a personal relationship with the individual and can secure a Discovery Call.</h5>
            </li>
            <li class="sale_commission_prodict">
              <h5 class="sale_commission_prodict_header">You have spoken directly with the lead and confirmed a level of interest in our services.</h5>
            </li>
            <li class="sale_commission_prodict">
              <h5 class="sale_commission_prodict_header">You have a current contract or financial relationship with the facility.</h5>
            </li>
          </ul>
          <p>Cold leads must not be entered into the system, you must make contact first and have a path to closing.</p>
        </div>
        <div class="policy-section">
          <p>16.2. Lead Ownership & Expiration</p>
          <ul class="sale_commission">
            <li class="sale_commission_prodict">
              <h5 class="sale_commission_prodict_header">Once a lead has been submitted and approved, it remains under your management for <strong>120 days</strong> to schedule a Discovery Call.</h5>
            </li>
            <li class="sale_commission_prodict">
              <h5 class="sale_commission_prodict_header">Leads will automatically expire after <strong>120 days</strong> if no Discovery Call is scheduled.</h5>
            </li>
            <li class="sale_commission_prodict">
              <h5 class="sale_commission_prodict_header">If additional time is required, you may request an extension from a Director. However, it is expected that Discovery Calls are scheduled well before expiration.</h5>
            </li>
          </ul>
        </div>
        <div class="policy-section">
          <p>16.3. Lead Registration Requirements</p>
          <ul class="sale_commission">
            <li class="sale_commission_prodict">
              <h5 class="sale_commission_prodict_header">When entering a lead into the system, you must provide detailed notes in the “Notes” section, specifying your relationship with the facility or contact.</h5>
            </li>
            <li class="sale_commission_prodict">
              <h5 class="sale_commission_prodict_header">Leads without contact information or a clear relationship explanation will be denied.</h5>
            </li>
            <li class="sale_commission_prodict">
              <h5 class="sale_commission_prodict_header">Denied leads may be resubmitted only after they have been warmed and meet the qualification criteria.</h5>
            </li>
          </ul>
        </div>
        <p>Failure to comply with this policy may result in lead reassignments.</p>



        <div class="footer_main_wrp">
          <div class="footer_sub_wrp">
            <h3 class="assciate_wrp">Associate Director:</h3>
            <p>Signature :<strong class="signature_wrpper">
                <mat-form-field class="textfirld_wrp signature_txt_wrppr">
                  <input matInput name="signature" formControlName="signature" autocomplete="new-password" required
                    [ngClass]="sign_flag ? 'textfirld_subwrp' : 'textfirld_subwrp'" (keydown)="runing($event)"
                    [readonly]="filedstatus" />
                </mat-form-field>
                <mat-error class="contact_error" *ngIf="errors?.signature">Signature is required</mat-error>
              </strong>
            </p>

            <p>Full Name: <strong>
                <mat-form-field class="textfirld_wrp">
                  <input matInput name="rep_name" formControlName="rep_name" [value]="loginForm.value.rep_name" class=""
                    readonly="true" (input)="runing($event)" />
                </mat-form-field>
                <mat-error class="contact_error" *ngIf="errors?.rep_name">Rep name is required</mat-error>
              </strong>
            </p>


            <p class="value_wrp">Date: <strong>{{ today }}</strong></p>

          </div>

          <div class="sign_wrp">

            <div class="sign_wrp_beto">
              <h3>By:</h3>
              <div class="sign_wrp_beto_img"> <img
                  src="https://all-frontend-assets.s3.amazonaws.com/betoparedes-v3/beto_signature.webp" />
              </div>
            </div>

            <p> Full Name:<strong>
                <mat-form-field class="textfirld_wrp">
                  <input matInput name="beto_name" formControlName="beto_name" required class=""
                    (input)="runing($event)" />
                </mat-form-field>
                <mat-error *ngIf="errors?.rep_name">Rep name is required</mat-error>
              </strong>
            </p>

            <p>Designation:Executive Director</p>

          </div>

        </div>

        <button [ngClass]=" contractData ? 'contract_btn_disable' : 'contract_button_wrp' " (click)="contractSubmit()"
          [disabled]="contractData ? true : false">
          Submit
        </button>

        <mat-progress-bar *ngIf="loader" mode="indeterminate" class="loader_wrp"></mat-progress-bar>

      </form>

    </div>
  </div>
</div>



<!-- -------------------------------------for download--------------------------------- -->

<div id="contract_form" *ngIf="sign_flag">
  <mat-progress-bar *ngIf="loader" mode="indeterminate" class="loader_wrp"></mat-progress-bar>

  <div *ngIf="extractedContent; else noContract" class="main_wrp" style="padding: 30px;">
    <div class="contract_button_wraper">
      <button class="printPageButton" matTooltip="Download Contract Form" (click)="clickPrint()">
        <i class="fa-solid fa-arrow-down" aria-hidden="true"></i>
      </button>

      <button class="printPageButton" matTooltip="Get Download Link in Mail" (click)="emailsend()">
        <i class="fa-solid fa-envelope-open-text" aria-hidden="true"></i>
      </button>
    </div>

    <div [innerHTML]="extractedContent"></div>
  </div>

  <ng-template #noContract class="no-data-container">
    <div class="no-contract">
      <div class="inner-no-contract">
        <p>Oops!</p>
        <p>Contract Not Generated Yet, Please Come Some Time Letter</p>
      </div>
    </div>
  </ng-template>
</div>


<!-- -------------------------------------for download--------------------------------- -->