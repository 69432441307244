<mat-icon class="close-btn-modal" (click)="closeModal()" mat-raised-button
matTooltip="Close"
matTooltipPosition="below">close</mat-icon>

 
    
    <div class="subwrapper" *ngIf="!showNoteAdd">
      <div class="formStyle user-form">
        <mat-card-content
          class="addEditPageWrapper practice_form_style form_title_style"
        >
        <div class="sub-title wrapper">
          <h2>Select Disposition</h2>
      </div>
          <!-- <ng-container *ngIf="addFormLoader">
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </ng-container> -->
          <div class="AddEditBlog taxonomies_formstyle">
            <div class="addEditForm">
              <lib-showform
                [formdata]="userformdata"
                [formfieldrefresh]="formfieldrefresh"
                [formfieldrefreshdata]="formfieldrefreshdata"
                (onFormFieldChange)="listenFormFieldChange($event)"
              >
              </lib-showform>
              <mat-progress-bar
                *ngIf="loader"
                mode="indeterminate"
              ></mat-progress-bar>
          </div>
          </div>
        </mat-card-content>
      </div>
    </div>
 

    <div class="subwrapper_customnoteAdd" *ngIf="showNoteAdd">
      <div class="dialoghead">
        <h1 class="mat-mdc-dialog-title">Submit</h1>
        <div class="textareanotewrapper">
          <textarea name="" id="" cols="25" rows="5" placeholder="Add Notes Here" (input)="getNoteValue($event)"></textarea>
          <button mat-button (click)="onNoteSubmitClick()">Submit</button>
        </div>
        <mat-progress-bar
                *ngIf="loader"
                mode="indeterminate"
              ></mat-progress-bar>
           
      </div>

    </div>

