<!-- <div class="mainwrapper"> -->
<!-- <div class="new-container"> -->

<!-- <div class="subwrapper"> -->
<div class="last_login_wrapper">
  <div class="last_login_sub-title">
    <section class="login_user">
      <i class="fa fa-user-circle-o" aria-hidden="true"></i>
      <h4 style="text-transform: capitalize;"><strong>Welcome: </strong>
        <span>{{user_login_details.userinfo.name}}</span></h4>
    </section>

    <section class="rep_details" *ngIf="user_login_details.userinfo.user_type === 'is_rep'">
      <span class="repid_wrapper">
        <i class="fa fa-id-card" aria-hidden="true"></i>
      </span>
      <h4><strong>Your Rep ID:</strong></h4>
      <span
        style="font-size: 25px;word-break: break-all;line-height: 26px;">{{user_login_details.userinfo.agent_code}}</span>
      <div class="steps step2 copy_button" matTooltip="Copy Rep ID"
        (click)="copyrepId(user_login_details.userinfo.agent_code)">
        <i class="fa fa-clone" aria-hidden="true"></i>
      </div>
    </section>
    <div *ngIf="user_login_details.userinfo.user_type === 'is_admin' && !user_login_details.userinfo.onlyexportAccess && !user_login_details.userinfo.only_lead_list_access" class="contact_view_wrppr">
      <h4><strong>Download Approved Leads Details:</strong></h4>
      <div class="copy_button contact_icon" matTooltip="Download Approved Leads Details"
        *ngIf="user_login_details.userinfo.user_type === 'is_admin'" (click)="handleButtonClick()">
        <i class="fa fa-download" aria-hidden="true"></i>
      </div>
    </div>

    <section class="login_time">
      <i class="fa fa-sign-out" aria-hidden="true"></i>
      <h4><strong>Your Last Login: </strong> <span>{{last_login}}</span></h4>
    </section>

    <div *ngIf="user_login_details.userinfo.user_type === 'is_rep'" class="contact_view_wrppr">
      <span class="view_icon_erppr">
        <i class="fa-solid fa-eye"></i>
      </span>
      <h4><strong>View Rep Contract:</strong></h4>
      <div class="copy_button contact_icon" matTooltip="View Rep Contract"
        *ngIf="user_login_details.userinfo.user_type === 'is_rep'" (click)="contractOpen()">
        <i class="fa fa-clone reviewContract" aria-hidden="true"></i>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->