import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';

@Component({
  selector: 'app-lead-preview',
  templateUrl: './lead-preview.component.html',
  styleUrls: ['./lead-preview.component.css']
})
export class LeadPreviewComponent {

  public created_on:any;
  public updated_on:any;
  public leadEmail: any;

  public otherInformation:any

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<LeadPreviewComponent>,){}

  ngOnInit(){
    console.log("this is lead preview data",this.data);

    this.leadEmail = this.data?.value?.email ? this.data.value.email : "N/A"
    this.created_on=moment(this.data.value.created_on).format('MMMM Do YYYY, h:mm:ss a');
    this.updated_on=moment(this.data.value.updated_on).format('MMMM Do YYYY, h:mm:ss a');
    this.otherInformation = this.data?.value?.other_information
  }


  closePreview() {
    this.dialogRef.close()
  }

  getKeys(obj: any): Array<string> {
    console.log(Object.keys(obj));
    
    return Object.keys(obj);
  }

  // formatDate(timestamp: number | undefined): string {
  //   if (timestamp) {
  //     const date = new Date(timestamp);
  //     return this.datePipe.transform(date, 'MMMM d yyyy') || ''; // Format the date
  //   }
  //   return '';
  // }

}
